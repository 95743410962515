.jss342 {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    height: 100px;
    border: 0;
    display: flex;
    position: relative;
    word-wrap: break-word;
    min-width: 0;
    font-size: .875rem;
    background: #fff;
    margin-top: 30px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 30px;
    border-radius: 6px;
    flex-direction: column;
}

.jss346.jss348.jss349 {
    text-align: right;
}


.jss356.jss357, .jss356.jss358, .jss356.jss359, .jss356.jss360, .jss356.jss361, .jss356.jss362 {
    float: left;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    border-radius: 3px;
    background-color: #999;
    font-size: 20px
}


.jss357 {
    background: linear-gradient(60deg, #66bb6a, #43a047);
    box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
}
.jss358 {
    background: linear-gradient(60deg, #ef5350, #e53935);
    box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2);
}
.jss346.jss348 i, .jss346.jss348 .material-icons {
    width: 56px;
    height: 56px;
    overflow: unset;
    font-size: 36px;
    text-align: center;
    line-height: 56px;
    margin-bottom: 1px;
}

.jss239 {
    color: #999999;
    margin: 0;
    font-size: 14px;
    margin-top: 0;
    padding-top: 10px;
    margin-bottom: 0;
}



.jss348 h1, .jss348 h2, .jss348 h3, .jss348 h4, .jss348 h5, .jss348 h6 {
    margin: 0 !important;
}

.jss349.jss350, .jss349.jss351, .jss349.jss352, .jss349.jss353, .jss349.jss354, .jss349.jss355 {
    background: transparent;
    box-shadow: none;
}
.jss346:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.jss346.jss347, .jss346.jss349, .jss346.jss348, .jss346.jss350, .jss346.jss351, .jss346.jss352, .jss346.jss353, .jss346.jss354, .jss346.jss355 {
    color: #FFFFFF;
    margin: 0 15px;
    margin-top: 10px;
    padding: 0;
    position: relative;
}
.jss241 {
    color: #3C4858;
    margin-top: 0px;
    min-height: auto;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin-bottom: 3px;
    text-decoration: none;
}