@charset "UTF-8";
* {
  margin: 0;
  outline: 0;
  padding: 0;
}
#zoomIn,
#zoomOut {
  cursor: pointer;
  padding: 5px 10px;
  background: #43b0e6;
  color: #fff;
  margin: 5px;
}
.node img {
  width: 75px;
  height: 75px;
  object-fit: cover;
}
.dashbanner {
  margin-bottom: 15px;
  margin-left: 0;
  color: #fff;
  width: 100%;
}
.dashbanner .col-md-3 {
  padding: 10px 15px !important;
  font-size: 14px;
  margin-bottom: 15px;
}
.dashbanner .col-md-2 {
  padding: 10px 15px !important;
  font-size: 12px;
  margin-bottom: 15px;
}
.dashbanner .col-md-12 {
  padding: 10px 15px !important;
  cursor: pointer;
  margin-bottom: 15px;
}
.dashbanner .col-md-12 span {
  vertical-align: middle;
  font-size: 16px;
  font-weight: bold;
}
.dashbanner .col-md-3 span,
.dashbanner .col-md-2 span {
  font-size: xx-large;
}
.divcol span {
  width: 100%;
}
.divcol span:before {
  font-size: 20px;
  margin-top: -10px;
  float: right;
  content: "-";
}
.divcol.collapsed span:before {
  font-size: 20px;
  margin-top: -10px;
  float: right;
  content: "+";
}
.box-blue {
  border: 1px solid #43b0e6;
  margin: 0;
  margin-bottom: 10px;
}
.box-blue-padding {
  border: 1px solid #43b0e6;
  padding: 10px;
  margin-top: 15px;
}
.panel-default {
  border: 1px solid #43b0e6;
  padding: 0 15px;
  margin: 15px 0;
}
.list-group-item {
  cursor: pointer;
  font-size: small;
  padding: 5px 25px;
}
.list-group {
  margin-bottom: 15px;
}
.box-blue-detail {
  border: 1px solid #43b0e6;
  padding: 0 10px 10px;
  margin-bottom: 5px;
}
a[aria-expanded="true"] .fa-angle-down {
  display: none;
}
a[aria-expanded="false"] .fa-angle-up {
  display: none;
}
.text-blue {
  color: #43b0e6;
}
.text-red {
  color: red;
}
.text-bold {
  font-weight: 700;
}
.text-xxlarge {
  font-size: xx-large;
}
.text-xlarge {
  font-size: x-large;
}
.text-large {
  font-size: large;
}
.text-medium {
  font-size: medium;
}
.text-small {
  font-size: small;
}
.text-xsmall {
  font-size: x-small;
}
.text-xxsmall {
  font-size: xx-small;
}
.btn-blue {
  border: 1px solid #43b0e6;
  padding: 5px 15px;
  color: #43b0e6;
  font-size: small;
}
.box-blue .form-group,
.customPeriod .form-group,
.myteam .form-group {
  margin-top: 0;
  margin-bottom: 15px;
}
.myteam .form-control.__checkbox {
  margin-top: 20px;
}
.box-blue .form-control.__checkbox {
  padding-top: 0;
}
.box-progress {
  margin-bottom: 15px;
  margin-left: -5px;
  margin-right: -5px;
}
.box-key {
  padding: 5px 0;
  border: 1px solid #eee;
}
.progress {
  height: auto;
}
.progress-bar2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
.newsdesc {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 3.6em;
  text-align: justify;
  margin-right: 0;
  padding-right: 1em;
}
.newsdesc:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
}
.newsdesc:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: #fff;
}
body,
html {
  height: 100%;
  width: 100%;
}
html.hidden {
  overflow: hidden;
}
html {
  -webkit-text-size-adjust: none;
}
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  font-size: 0;
}
body {
  background: #f5f5f5;
  color: #333;
  direction: ltr;
  font-size: 16px;
  line-height: 1.4;
  overflow-x: hidden;
}
code,
kbd,
pre,
samp,
tt {
  font-size: 13px;
}
fieldset {
  min-width: 0;
  width: 100%;
}
.rte {
  min-height: 200px;
}
@-moz-document url-prefix() {
  fieldset {
    display: table-cell;
  }
}
ol,
ul {
  list-style: none;
}
.list.__inline,
.menu.__inline {
  word-spacing: -4px;
}
ol li,
ul li {
  padding-bottom: 2px;
  padding-left: 15px;
  padding-top: 2px;
  text-indent: -15px;
}
.list .list-item,
.menu .menu-item,
.tree .tree-item {
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 0;
  text-indent: 0;
}
.list .list-item::before,
.menu .menu-item::before,
.tree .tree-item::before {
  content: " ";
}
.__inline .list-item,
.__inline .menu-item {
  display: inline-block;
  vertical-align: top;
  word-spacing: 0;
}
.__actions .list-item,
.__actions .menu-item {
  display: inline-block;
  padding: 0 2px;
  vertical-align: top;
  word-spacing: 0;
}
.list.__notice .list-item {
  background: inherit;
  box-shadow: inherit;
  height: 28px;
  line-height: 28px;
  position: inherit;
  text-align: left;
}
.list.__notice .list-item:hover {
  box-shadow: none;
}
.list.__notice .list-link {
  color: #4c4c4c;
  display: block;
  font-size: 14px;
  padding: 0 10px;
}
.list.__notice .list-link:hover {
  background: #ececec;
}
.list.__notice .list-ico {
  font-size: 18px;
  margin: 0 2px 0 0;
  width: 25px;
}
.list.__notice .list-item.__error .list-ico {
  color: #e51400;
}
.list.__notice .list-item.__warning .list-ico {
  color: #f0a30a;
}
.list.__car-info {
  display: inline-block;
}
.list.__car-info .list-item {
  font-size: 14px;
  margin: 15px 0 0;
  padding: 0 0 0 35px;
  position: relative;
  text-align: left;
}
.list.__car-info .tile-ico {
  font-size: 18px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 25px;
}
.list.__car-info .list-name {
  color: #43b0e6;
  display: block;
}
.tile.double .list.__car-info .tile-ico {
  margin: 0;
}
.menu.__theme-links {
  padding: 10px 0;
  word-spacing: -11px;
}
.menu.__theme-links .menu-item {
  text-align: center;
  width: 33.3333%;
}
.menu.__theme-links .menu-link {
  display: block;
  font-size: 13px;
}
.menu.__theme-links .menu-ico {
  display: block;
  font-size: 17px;
  margin: 0 0 5px;
}
.menu.__context {
  background: #333;
  display: none;
  position: fixed;
  padding: 10px 0;
  z-index: 100;
}
.menu.__context .menu-item {
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  padding: 5px 20px;
  position: relative;
  text-align: left;
  text-shadow: none;
  white-space: nowrap;
}
.menu.__context .menu-item:hover {
  color: #43b0e6;
}
.menu.__context .menu-item:hover .menu.__sub {
  display: block;
}
.menu.__context .menu-item.__right {
  padding: 5px 40px 5px 20px;
}
.menu.__context .menu-item.__right .menu-ico {
  position: absolute;
  right: 20px;
  width: 6px;
}
.menu.__context .menu-ico {
  font-size: 18px;
  width: 25px;
}
.menu.__context .menu.__sub {
  background: #333;
  display: none;
  left: 100%;
  padding: 10px 0;
  position: absolute;
  top: -10px;
}
.menu.__context.__open,
.menu.__context.open {
  display: block;
}
.list.__info {
  margin: 15px 0;
}
.list.__info > .list-item {
  padding: 5px 0;
}
.list.__info .list-t {
  color: #4c4c4c;
  display: block;
  font-size: 14px;
}
.list.__info .list-descr {
  color: #999;
  font-size: 12px;
  margin: 5px 0 0;
  word-wrap: break-word;
  word-break: break-word;
}
.list.__info .list-link {
  font-size: 12px;
}
.list.__tags {
  margin: 5px 0 0;
  word-spacing: -4px;
}
.list.__tags .list-item {
  background: #43b0e6;
  color: #fff;
  display: inline-block;
  line-height: 1.1;
  margin: 0 5px 0 0;
  padding: 5px 10px;
  word-spacing: 0;
}
.list.__tags .list-ico {
  font-size: 14px;
  vertical-align: 2px;
}
.list.__tags .list-name {
  display: inline-block;
  font-size: 12px;
  vertical-align: 3px;
}
.list.__number {
  counter-reset: num;
}
.list.__number .list-item {
  padding: 0 0 0 20px;
  position: relative;
}
.list.__number .list-item::before {
  color: #a6a6a6;
  content: counter(num) ". ";
  counter-increment: num;
  left: 0;
  position: absolute;
  top: 2px;
}
h1 {
  font-size: 53px;
}
h2 {
  font-size: 44px;
}
h3 {
  font-size: 36px;
}
h1,
h2,
h3 {
  color: #4c4c4c;
  font-weight: 300;
  line-height: 1;
}
.t {
  font-size: 32px;
}
.sub-t {
  font-size: 24px;
  margin: 20px 0 10px;
}
.sub-t,
.t {
  font-weight: 300;
  line-height: 1;
}
.sub-t.__border {
  border-bottom: 1px solid #dee9f0;
  margin: 20px 0 0;
  padding: 0 0 10px;
}
.sub-t + .sub-t,
.t + .t {
  margin: 20px 0 10px;
}
.t.__divider {
  background: #fbfdfe;
  border-color: #dee9f0;
  border-style: solid;
  border-width: 1px 0;
  margin: 0 -20px 10px;
  padding: 10px 20px;
  position: relative;
}
.t.__divider .t-ico {
  position: absolute;
  width: 32px;
  background-size: 32px;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.t.__divider + .t.__divider {
  margin-top: 20px;
}
.text {
  font-size: 14px;
  margin: 5px 0;
}
.text.__debug {
  color: #a6a6a6;
  font-size: 11px;
  word-wrap: break-word;
}
.text.__note {
  border: 1px solid #eee;
  margin: 10px 0;
  padding: 10px;
}
.text.__note span:first-child {
  color: #e51400;
}
.text.__editable {
  background: #fbfdfe;
  border: 1px dashed #dee9f0;
  color: #43b0e6;
  cursor: text;
  padding: 5px 10px;
}
.text.__editable.__selected,
.text.__editable:hover {
  background-color: #ecf7fc;
  border-color: #a1c0d4;
}
.sub-text {
  font-size: 14px;
}
.text-mini {
  font-size: 11px;
}
.__right {
  text-align: right;
}
.note,
.note.error,
.note.info,
.note.success,
.note.warning {
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  margin: 20px 0;
  padding: 10px;
}
.note {
  background: #f9f9f9;
  border-color: #eee;
  color: #999;
}
.error {
  background: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.success {
  background: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.warning {
  background: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.info {
  background: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.table-wrapper {
  margin: 0 -20px 0;
}
.form .table-wrapper {
  margin: 0 -36px 15px -20px;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  padding: 0;
  width: 100%;
}
.table.__fixed {
  table-layout: fixed;
}
.table .table-head {
  border-bottom: 1px solid #dee9f0;
  font-size: 14px;
  padding: 5px 20px;
}
.table .table-col {
  border-color: #dee9f0;
  border-style: solid;
  border-width: 1px 0;
  font-size: 12px;
  padding: 10px;
  text-align: left;
}
.table .table-col:first-child {
  padding-left: 20px;
}
.table .table-col:last-child {
  padding-right: 20px;
}
.table.__data .table-col {
  vertical-align: top;
}
.table th.table-col {
  background: rgba(0, 0, 0, 0.025);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
}
.table .table-item {
  cursor: pointer;
}
.table .table-ico .sort {
  font-size: 16px;
  margin: 5px 0 0 10px;
}
.table .table-ico.sort.fa-sort-down {
  margin: -8px 0 0 10px;
}
.table.__indexed th:nth-last-child(2) ~ th {
  width: 100%;
}
.table.__indexed th:nth-last-child(3) ~ th {
  width: 50%;
}
.table.__indexed th:nth-last-child(4) ~ th {
  width: 33.33333%;
}
.table.__indexed th:nth-last-child(5) ~ th {
  width: 25%;
}
.table.__indexed th:nth-last-child(6) ~ th {
  width: 20%;
}
.table-info {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.table-descr,
.table-t {
  display: block;
  font-size: 12px;
}
.table-t {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.table-t .table-descr {
  display: inline;
}
.table-descr {
  color: #999;
}
.table-progress {
  margin: 10px 0 0;
  position: relative;
}
.table-status {
  background: #a6a6a6;
  color: #fff;
  display: block;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  padding: 0 5px;
  text-align: center;
  text-shadow: none;
}
.table-status.__active {
  background: #43b0e6;
}
.table-date {
  color: #999;
  font-size: 12px;
}
.table-img {
  border: none;
  height: auto;
  max-width: 40px;
  vertical-align: middle;
}
.table-ico {
  color: #a6a6a6;
  font-size: 28px;
  margin: 0 10px;
  text-shadow: 1px 1px #fff;
  vertical-align: middle;
}
.table-item.__error .table-ico {
  color: #e51400;
}
.table-item.__warning .table-ico {
  color: #f0a30a;
}
.table-item.__info .table-ico {
  color: #43b0e6;
}
.product-img {
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}
.product-img .image {
  background-blend-mode: darken;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  margin: 0 0 0 6px;
  position: relative;
  width: 100%;
}
.product-img img.image {
  height: auto;
}
.product-img .table-badge {
  position: absolute;
  left: 1px;
  top: 2px;
  width: 16px;
}
.table-item.__selected .product-img .image {
  background-color: #d9effa;
}
.product .table-ico {
  color: #a6a6a6;
  font-size: 28px;
  margin: 0 10px;
  text-shadow: 1px 1px #fff;
  vertical-align: middle;
}
.ui-grid,
.ui-grid-header {
  border-color: #dee9f0;
}
.ui-grid-viewport {
  overflow-x: auto !important;
}
.ui-grid-top-panel {
  background: rgba(0, 0, 0, 0.024);
}
.ui-grid-header-cell {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle;
}
.ui-grid-cell {
  border-style: solid;
  border-width: 0 0 1px;
  font-size: 14px;
  cursor: pointer;
}
.ui-grid-cell .table-descr {
  color: #999;
  font-size: 12px;
}
.ui-grid-pinned-container.ui-grid-pinned-container-left
  .ui-grid-cell:last-child {
  border-color: #dee9f0;
  border-top: none;
}
.ui-grid-row .ui-grid-cell.ui-grid-row-header-cell {
  border-color: #dee9f0;
}
.ui-grid-row.ui-grid-cell,
.ui-grid-row:nth-child(2n) .ui-grid-cell {
  -webkit-transition: background-color 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out;
}
.ui-grid-row:nth-child(2n) .ui-grid-cell {
  background: 0 0;
}
.ui-grid-row:nth-child(2n + 1) .ui-grid-cell.__selected {
  background: #d9effa !important;
}
.ui-grid-row.ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
  background: 0 0;
}
.ui-grid-row .ui-grid-cell.__hovered,
.ui-grid-row:hover .ui-grid-cell,
.ui-grid-row:nth-child(2n) .ui-grid-cell.__hovered,
.ui-grid-row:nth-child(2n):hover .image,
.ui-grid-row:nth-child(2n):hover .ui-grid-cell {
  background-color: #ecf7fc !important;
}
.ui-grid-row:nth-child(2n) .ui-grid-cell.__selected .image,
.ui-grid-row:nth-child(2n + 1) .ui-grid-cell.__selected .image {
  background-color: #d9effa !important;
}
.ui-grid-pinned-container.ui-grid-pinned-container-left
  .ui-grid-row:hover
  .ui-grid-cell,
.ui-grid-pinned-container.ui-grid-pinned-container-left
  .ui-grid-row:nth-child(2n):hover
  .ui-grid-cell {
  background: 0 0 !important;
}
.ui-grid-row.ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell.__selected {
  background: #d9effa !important;
}
.ui-grid-cell,
.ui-grid-header-cell,
.ui-grid-header-cell:last-child .ui-grid-column-resizer.right {
  border-color: #dee9f0;
}
.ui-grid-canvas {
  padding: 0;
}
.ui-grid-cell,
.ui-grid-cell .ui-grid-vertical-bar,
.ui-grid-row {
  overflow: visible;
  position: static;
  width: 100%;
}
.ui-grid-scrollbar-placeholder {
  height: 0 !important;
}
.ui-grid-row-header-cell > :first-child {
  height: 100%;
}
.ui-grid-header-cell .ui-grid-selection-row-header-buttons {
  margin-right: 2px;
}
.ui-grid-header-cell .ui-grid-cell-contents:after {
  display: inline;
}
.ui-grid-pinned-container-left .ui-grid-cell-contents,
.ui-grid-row-header-cell .ui-grid-cell-contents {
  text-align: center;
}
.ui-grid-cell-contents {
  display: inline-block;
  font-size: 14px;
  height: 100%;
  padding: 0 0 0 5px;
  text-shadow: 1px 1px #fff;
  vertical-align: middle;
  width: 100%;
}
.ui-grid-cell-contents.ui-grid-cell-contents-hidden {
  display: none;
}
.ui-grid-cell .form-editor {
  height: 31px;
  padding: 0 10px;
  margin: 14px 0;
  position: relative;
}
.ui-grid-cell .form-editor .form-input {
  border-color: #dee9f0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 10px;
}
.ui-grid-cell-focus {
  background: #d9effa;
}
.ui-grid-focuser:focus {
  box-shadow: none;
}
.ui-grid-cell.__filters .ui-grid-cell-contents {
  background: #f6f6f6;
}
.ui-grid-cell-contents .inner-t {
  font-size: 16px;
  font-weight: 400;
}
.ui-grid-cell-contents .count {
  color: #43b0e6;
  font-weight: 600;
}
.ui-grid-cell-contents .inner-contents {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.ui-grid-cell-contents::after {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.ui-grid-icon-cancel::before {
  visibility: hidden;
}
.ui-grid-row:last-child .ui-grid-cell {
  border-bottom-color: #dee9f0;
  border-bottom-width: 1px !important;
}
.ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid {
  overflow-x: hidden !important;
  overflow: auto;
}
.ui-grid-menu .ui-grid-menu-inner {
  background: #fff;
  border: none;
  box-shadow: none;
}
.ui-grid-menu .ui-grid-menu-inner ul li button {
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}
.ui-grid-menu .ui-grid-menu-inner ul li button:focus,
.ui-grid-menu .ui-grid-menu-inner ul li button:hover {
  background: #ecf7fc;
  box-shadow: none;
}
.ui-grid-selection-row-header-buttons {
  color: #a1c0d4;
  height: 100%;
  opacity: 1;
}
.ui-grid-selection-row-header-buttons::after {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.ui-grid-selection-row-header-buttons::before {
  content: "";
  font-size: 17px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  margin: 0;
}
.ui-grid-selection-row-header-buttons.ui-grid-all-selected::before,
.ui-grid-selection-row-header-buttons.ui-grid-row-selected::before {
  content: "";
  margin: 0 0 0 1px;
}
.ui-grid-row:nth-child(2n) .ui-grid-cell.__selected {
  background: #d9effa !important;
}
.ui-grid-pinned-container.ui-grid-pinned-container-left
  .ui-grid-cell.__selected,
.ui-grid-pinned-container.ui-grid-pinned-container-left
  .ui-grid-row:nth-child(2n + 1)
  .ui-grid-cell.__selected {
  background: 0 0 !important;
}
.ui-grid-filter-container {
  padding: 0 10px 10px;
}
input.ui-grid-filter-input[type="text"] {
  padding: 5px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ui-grid-filter-container .ui-grid-filter-button [class^="ui-grid-icon"] {
  line-height: 2;
  margin: 0;
  top: 0;
}
.ui-grid[dir="rtl"] .ui-grid-filter-container .ui-grid-filter-button {
  right: inherit;
}
.ui-grid[dir="rtl"]
  .ui-grid-filter-container
  .ui-grid-filter-button
  [class^="ui-grid-icon"] {
  right: inherit;
}
.ui-grid-column-menu-button {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ui-grid-column-menu-button .ui-grid-icon-angle-down {
  vertical-align: top;
}
.ui-grid-row-header-cell {
  background: 0 0 !important;
}
.ui-grid-pinned-container.ui-grid-pinned-container-left
  .ui-grid-header-cell:last-child {
  border-color: #dee9f0;
}
.ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar {
  background: 0 0;
}
.ui-grid-column-menu-button {
  display: none;
}
.ui-grid-cell .product-img {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
}
.ui-grid-cell .product-img .pr-ico {
  color: #a6a6a6;
  display: inline-block;
  font-size: 28px;
  margin: -4px 0 0;
  text-shadow: 1px 1px #fff;
  vertical-align: middle;
}
.ui-grid-row-selected .ui-grid-cell .pr-ico,
.ui-grid-row:hover .ui-grid-cell .pr-ico {
  color: #a1c0d4;
}
.ui-grid-row > :first-child {
  height: 100%;
  overflow: visible;
  position: relative;
}
.ui-grid-render-container-body .ui-grid-header-cell,
.ui-grid-render-container-left .ui-grid-header-cell {
  height: 40px;
}
.ui-grid-menu-button {
  right: 4px;
  top: 4px;
}
.blade-inner:hover .ui-grid.__scrolled .ui-grid-menu-button {
  right: 20px;
}
.ui-grid-menu-button {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  height: 32px;
  padding: 1px 0 0;
  text-align: center;
  width: 32px;
}
.ui-grid-sort-priority-number {
  color: #43b0e6;
}
.ui-grid-menu-button .ui-grid-icon-menu::before {
  width: 9px;
}
.ui-grid-menu-button .ui-grid-menu {
  margin: 6px -1px 0 0;
  min-width: 160px;
  padding: 0;
}
.ui-grid-menu .ui-grid-menu-inner ul li {
  text-indent: 0;
}
.ui-grid-menu .ui-grid-menu-inner ul li::before {
  display: none;
}
.ui-grid-actions {
  height: 100%;
  position: relative;
  text-align: center;
  z-index: 50;
}
.ui-grid-actions::after {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.ui-grid-actions .fa {
  color: #43b0e6;
  font-size: 21px;
  vertical-align: middle;
  -webkit-transition: font 0.3s ease;
  transition: font 0.3s ease;
}
.ui-grid-actions .fa.context-menu::before {
  content: "\f142";
}
.ui-grid-actions ~ .menu {
  background: #dee9f0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  white-space: nowrap;
  z-index: 10;
  -webkit-transition: width 0.35s ease 0.5s;
  transition: width 0.35s ease 0.5s;
}
.ui-grid-actions ~ .menu::after {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.ui-grid-actions ~ .menu .menu-item {
  display: inline-block;
  vertical-align: middle;
}
.ui-grid-actions ~ .menu .menu-link {
  display: block;
  padding: 0 10px;
}
.ui-grid-actions ~ .menu .menu-ico {
  width: 18px;
}
.ui-grid-actions.__opened ~ .menu {
  width: -webkit-calc(100% - 80px);
  width: calc(100% - 80px);
}
.ui-grid-draggable-row-target {
  opacity: 0.5 !important;
}
.ui-grid-draggable-row {
  height: 60px;
}
.ui-grid-draggable-row-over {
  position: relative;
}
.ui-grid-draggable-row-over:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: 1px dashed grey;
}
.ui-grid-draggable-row-over--above:before {
  top: 0;
}
.ui-grid-draggable-row-over--below:before {
  bottom: 0;
}
:-moz-ui-invalid:not(output) {
  box-shadow: none;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #dbdbdb;
  opacity: 0.5;
  color: #000;
}
input::-ms-clear {
  display: none;
}
.__error {
  color: #e51400;
}
.form-input input.ng-invalid,
.form-input input.ng-invalid.form-input,
.form-input input.ng-invalid:focus,
.form-input textarea.ng-invalid,
.form-input textarea.ng-invalid:focus,
.ui-select-container.ng-invalid .select2-choice,
.ui-select-container.ng-invalid .select2-choices {
  background: #fef8f7;
  border-color: #fce7e6;
  color: #e51400;
}
fieldset {
  border: none;
}
fieldset legend {
  border-bottom: 1px solid #dee9f0;
  display: block;
  font-size: 24px;
  font-weight: 300;
  padding: 0 0 5px;
  width: 100%;
}
fieldset + fieldset {
  margin: 15px 0 0;
}
.__gray .form-label,
.__gray fieldset legend {
  color: #4c4c4c;
}
.form {
  padding: 15px 0;
}
.form > .form-group:first-child {
  margin-top: 0;
}
.form > .form-group:first-child > .column > .form-group:first-child {
  margin-top: 0;
}
.form-group.__head {
  border-bottom: 1px solid #dee9f0;
}
.__inline .form-group {
  display: inline-block;
}
.form-group {
  margin: 15px 0 0;
}
.__inline .form-group + .form-group {
  margin-left: 15px;
}
.form-group .form-hint {
  color: #999;
  font-size: 12px;
}
.form-error {
  color: #e51400;
  font-size: 12px;
  line-height: 1.4;
  padding: 5px 0;
}
.form-error * {
  display: block;
}
.form .columns {
  padding: 0;
}
.form-group .form-input ~ .form-error,
.form-group .form-input ~ .form-hint {
  margin: 5px 0 0;
}
.form-input {
  position: relative;
}
.form .form-input + .form-input {
  margin: 15px 0 0;
}
.form .form-count {
  font-size: 14px;
}
.count {
  color: #43b0e6;
  font-weight: 600;
}
.form-input.__number {
  height: 34px;
  position: relative;
}
.form-group .form-t {
  background: #fbfdfe;
  border: 1px solid #dee9f0;
  margin: 0 0 5px;
  padding: 5px;
}
.form-group .form-t .__link {
  float: right;
  margin: 4px 0 0;
}
.form-input input,
.form-input select,
.form-input textarea {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #4c4c4c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 13px;
  vertical-align: top;
  width: 100%;
}
.form-input.__mini {
  width: 68px;
}
.form-input.__inline {
  display: inline-block;
  vertical-align: middle;
}
.table-col .form-input.__mini {
  margin: 5px 0 0;
}
.table-col .form-input .form-text {
  margin: 5px 0 0;
}
.table-col .table-space {
  height: 17px;
}
.form-input .form-text,
.form-input input,
.form-input select {
  height: 34px;
}
.form-input input,
.form-input select,
.form-input textarea {
  border: 1px solid #dee9f0;
  padding: 5px 10px;
}
.form-input textarea {
  height: 70px;
  max-width: 100%;
  min-height: 70px;
  min-width: 100%;
  overflow: auto;
  padding-top: 8px;
}
.form-input textarea.__small {
  height: 55px;
  min-height: 55px;
}
.form-input textarea.__large {
  height: 220px;
  min-height: 220px;
}
.form-input textarea.__xlarge {
  height: 330px;
  min-height: 330px;
}
.form-input.__langs input,
.form-input.__langs select,
.form-input.__langs textarea {
  padding-left: 36px;
}
.form-input.__currency input,
.form-input.__currency select,
.form-input.__currency textarea {
  padding-right: 40px;
}
.form-input.__number input {
  padding-right: 20px;
}
.form-input.__calendar input,
.form-input.__file input,
.form-input.__info input,
.form-input.__other input {
  padding-right: 45px;
}
.form-input select {
  position: relative;
}
.form-input input:focus,
.form-input select:focus,
.form-input textarea:focus {
  border-color: #a1c0d4;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-input input[type="file"] {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
  z-index: 0;
}
.form-input.__number .down,
.form-input.__number .up {
  background: #fbfdfe;
  border: 1px solid #dee9f0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  height: 14px;
  right: 2px;
  position: absolute;
  width: 16px;
}
.form-input.__number .up {
  top: 2px;
}
.form-input.__number .up::after {
  border-color: transparent transparent #43b0e6;
  bottom: 4px;
}
.form-input.__number .down {
  bottom: 2px;
}
.form-input.__number .down::after {
  border-color: #43b0e6 transparent transparent;
  top: 4px;
}
.form-input.__number .down::after,
.form-input.__number .up::after {
  border-style: solid;
  border-width: 4px;
  content: "";
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.form-input.__langs .flag {
  left: 11px;
  position: absolute;
  top: 11px;
  z-index: 50;
}
.form-input.__currency {
  position: relative;
}
.form-input.__currency .currency {
  color: #43b0e6;
  font-size: 12px;
  right: 10px;
  position: absolute;
  top: 10px;
  z-index: 50;
}
.form-label {
  display: inline-block;
  font-size: 12px;
  margin: 0 0 5px;
}
.form-edit {
  display: inline-block;
  margin: 0 0 0 5px;
}
.form-edit .form-ico {
  vertical-align: 2px;
}
.form-label + .form-nav {
  float: right;
}
.form-label + .form-nav * {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 0 5px;
}
.form-input .btn {
  background: 0 0;
  bottom: 0;
  color: #000;
  height: 34px;
  line-height: 1;
  min-width: 45px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.form-input .form-text {
  font-size: 14px;
  line-height: 34px;
}
.form-input .btn-ico {
  color: #43b0e6;
  font-size: 24px;
  height: 26px;
  padding: 0 10px;
}
.form-input.__info .btn-ico {
  color: #fff;
  font-size: 16px;
  height: auto;
  text-shadow: none;
  width: 20px;
}
.form-input.__info .btn-ico::before {
  background: #43b0e6;
  border-radius: 50%;
  display: block;
  height: 20px;
  line-height: 20px;
}
.form-input .btn.__other .btn-ico {
  font-size: 12px;
  line-height: 24px;
}
.form-control .form-label {
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
}
.form-control .form-label + .form-label {
  margin-left: 10px;
}
label.form-control {
  display: inline-block;
  height: 12px;
  position: relative;
  top: -2px;
  vertical-align: middle;
  width: 12px;
}
.form-control {
  min-height: 12px;
  position: relative;
}
.form-control.__inline {
  display: inline-block;
  margin: 0 20px 0 0;
  vertical-align: middle;
}
.form-control.__checkbox {
  height: 25px;
  border: none;
}
.form-control.__radio {
  padding-right: 0;
}
.blade-static .form-control.__checkbox,
.blade-static .form-control.__radio {
  margin: 0 0 10px;
}
.form-control.__checkbox + .form-control,
.form-control.__radio + .form-control {
  margin-top: 5px;
}
.form-control.__checkbox .form-label,
.form-control.__radio .form-label {
  line-height: 1.1;
  margin-bottom: 0;
  vertical-align: top;
}
.form-control input {
  position: absolute;
  visibility: hidden;
}
.form-control .check {
  background: #fff;
  border: 1px solid #a1c0d4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  width: 13px;
}
.form-control.__radio .check {
  border-radius: 50%;
  top: auto;
}
.form-control .form-label:hover .check,
.form-control :checked ~ .check {
  border-color: #80a0b5;
}
.form-control :checked ~ .check:after {
  content: "";
  position: absolute;
}
.form-control.__checkbox :checked ~ .check:after {
  bottom: 0;
  color: #43b0e6;
  content: "";
  font-family: FontAwesome;
  font-size: 11px;
  font-weight: 400;
  left: 0;
  line-height: 1.3;
  position: absolute;
  top: -1px;
}
.form-control.__radio :checked ~ .check:after {
  background: #43b0e6;
  border-radius: 50%;
  height: 7px;
  left: 2px;
  top: 2px;
  width: 7px;
}
.form-control .name {
  color: #4c4c4c;
  display: inline-block;
  margin: 0 0 0 18px;
  vertical-align: 2px;
}
.__switch {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: top;
  width: 60px;
}
.__switch input {
  display: none;
}
.__switch .switch {
  background: #555;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  display: block;
  height: 20px;
  width: 60px;
  -webkit-transition: background 0.15s ease;
  transition: background 0.15s ease;
}
.__switch .switch::after {
  background: #fff;
  border-radius: 3px;
  color: #ccc;
  content: "|||";
  font-size: 11px;
  height: 18px;
  left: 0;
  line-height: 16px;
  margin: 1px 0 0 1px;
  position: absolute;
  text-align: center;
  text-indent: -1px;
  width: 25px;
  -webkit-transition: margin-left 0.15s ease;
  transition: margin-left 0.15s ease;
}
.__switch .switch::before {
  color: #fff;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  width: 35px;
}
.__switch.__two::after,
.__switch.__two::before {
  content: "";
  position: absolute;
  top: 6px;
}
.__switch.__two::after {
  background: #999;
  height: 10px;
  right: -7px;
  width: 1px;
}
.__switch.__two::before {
  border: 1px solid #999;
  border-radius: 50%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 8px;
  left: -15px;
  width: 8px;
}
.__switch :checked ~ .switch {
  background: #43b0e6;
}
.__switch :checked ~ .switch::after {
  margin-left: 34px;
}
.__switch :checked ~ .switch::before {
  content: "";
  left: 0;
  right: auto;
}
.editable {
  font-size: 14px;
}
.editable .btn {
  padding: 0 10px;
}
.editable .btn-toolbar {
  background: #43b0e6;
}
.editable .ta-scroll-window.form-control {
  border: 1px solid #dee9f0;
}
.ta-root.focussed > .ta-scroll-window.form-control {
  border-color: #a1c0d4;
  box-shadow: none;
}
.ta-editor.ta-html,
.ta-scroll-window.form-control {
  border: 1px solid #a1c0d4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}
.btn {
  background: #43b0e6;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 13px;
  height: 30px;
  line-height: 28px;
  padding: 0 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align: top;
}
.btn:hover {
  color: #fff;
  opacity: 0.8;
}
button.btn {
  padding: 0 17px;
}
.btn.__loading {
  font-size: 0;
  min-width: 132px;
  pointer-events: none;
  position: relative;
  vertical-align: top;
}
.btn.__loading::after {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 30px 30px;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-animation: linear 2s infinite progress;
  animation: linear 2s infinite progress;
}
.btn.__cancel {
  background-color: #a6a6a6;
}
.btn.__cancel:hover {
  background-color: #a0a0a0;
}
.md-editor {
  background: #fbfdfe;
  border: 1px solid #dee9f0;
  margin: -1px -20px 0;
}
.md-editor textarea {
  background: 0 0;
  border: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  height: 300px;
  padding: 10px;
  width: 100%;
}
.md-editor .btn-toolbar {
  background: #dee9f0;
  padding: 10px;
  overflow: hidden;
}
.md-editor .btn-toolbar .btn-group {
  float: left;
  margin: 0 5px 0 0;
}
.md-editor .btn-toolbar .btn-group .btn {
  padding: 0 10px;
}
.md-editor .btn-toolbar .btn-group .btn.hidden {
  display: none !important;
  visibility: hidden !important;
}
.md-editor .btn-toolbar .btn-group .btn + .btn {
  border-left: 1px solid rgba(255, 255, 255, 0.35);
}
.md-editor .btn-toolbar .md-controls {
  float: right;
  margin: 3px 10px 0 0;
}
.md-editor.md-fullscreen-mode {
  background: #fff !important;
  border: 0 none !important;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 60px 30px 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}
.md-editor.active {
  border-color: #66afe9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0 none;
}
.md-fullscreen-controls {
  display: none;
}
.md-fullscreen-controls .md-header {
  background: 0 0;
  position: fixed;
  text-align: center;
  top: 20px;
  width: 100%;
}
.md-fullscreen-controls .btn-group {
  display: inline-block;
  float: none;
}
.md-fullscreen-controls .md-fullscreen-controls {
  display: block;
  position: absolute;
  right: 20px;
  text-align: right;
  top: 20px;
  z-index: 1002;
}
.md-fullscreen-controls .md-fullscreen-controls a {
  clear: right;
  color: #b3b3b3;
  height: 30px;
  margin: 10px;
  text-align: center;
  width: 30px;
}
.md-editor.md-fullscreen-mode .md-input,
.md-editor.md-fullscreen-mode .md-preview {
  padding: 20px 20px 20px 120px !important;
}
.tile-group {
  margin: 0 -10px 0 0;
  min-width: 130px;
  overflow: hidden;
  position: relative;
  width: auto;
}
.tile-group + .tile-group {
  margin: 20px 0 0;
}
.dashboard .tile-group {
  overflow: visible;
}
.tile-group .tile-group,
.tile-group .tile-group + .tile-group {
  float: left;
  margin: 0;
  padding: 0;
}
.tile-group.one {
  width: 130px;
}
.tile-group.two {
  width: 260px;
}
.tile-group.three {
  width: 390px;
}
.tile-group.four {
  width: 520px;
}
.tile-group.five {
  width: 650px;
}
.tile-group.six {
  width: 780px;
}
.tile-group.seven {
  width: 910px;
}
.tile {
  height: 120px;
  width: 120px;
}
.tile.double {
  height: 250px;
  width: 250px;
}
.tile.double.tall {
  height: 250px;
  width: 120px;
}
.tile.double.wide {
  height: 120px;
  width: 250px;
}
.tile.triple {
  height: 380px;
  width: 380px;
}
.tile.triple.tall {
  height: 380px;
  width: 250px;
}
.tile.triple.wide {
  height: 250px;
  width: 380px;
}
.tile.triple.wide.mini {
  height: 120px;
  width: 380px;
}
.tile.quadro {
  height: 510px;
  width: 510px;
}
.tile.quadro.tall {
  height: 510px;
  width: 380px;
}
.tile.quadro.wide {
  height: 380px;
  width: 510px;
}
.tile.quadro.wide.mini {
  height: 120px;
  width: 510px;
}
.tile {
  background: #fbfdfe;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 0 0 2px #dee9f0 inset;
  color: #4c4c4c;
  cursor: pointer;
  float: left;
  font-size: 48px;
  margin: 10px 10px 0 0;
  padding: 2px;
  position: relative;
  text-align: center;
  vertical-align: top;
}
.tile::before {
  opacity: 0;
}
.tile .tile-ico {
  margin: 25px 0 0;
}
.tile .btn {
  display: inline-block;
  line-height: 28px;
  vertical-align: top;
}
.tile .tile-descr {
  display: block;
  font-size: 14px;
  margin: 10px 0 5px;
  max-height: 78px;
  overflow: hidden;
}
.tile .tile-count {
  color: #999;
  font-size: 30px;
  line-height: 1;
  margin: 0 0 10px;
}
.tile .tile-t {
  font-size: 24px;
  margin: 10px 0;
}
.tile .tile-img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}
.tile .tile-action {
  background: #dee9f0;
  height: 30px;
  line-height: 30px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  -webkit-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}
.tile:hover .tile-action {
  background-color: #a1c0d4;
  opacity: 1;
}
.tile:hover .tile-action * {
  color: #fff;
  font-size: 17px;
}
.tile .tile-action:nth-child(2) {
  left: auto;
  right: 0;
}
.tile.__price {
  line-height: 0;
}
.tile.__price * {
  display: block;
  line-height: 1;
}
.tile.__price .tile-count {
  color: #43b0e6;
  font-size: 24px;
  margin: 10px 0 0;
}
.tile.__price .tile-price {
  color: #43b0e6;
  font-size: 30px;
  height: 49px;
  line-height: 40px;
  margin: 20px 0 0;
}
.tile.__price .tile-descr + .tile-price {
  font-size: 14px;
  height: auto;
  line-height: 1;
}
.tile.__selected,
.tile:hover {
  box-shadow: 0 0 0 2px #a1c0d4 inset;
}
.tile.__info:hover,
.tile.__selected.__info {
  cursor: default;
  box-shadow: none;
}
.__note {
  background-color: #fffff5;
}
.tile.__selected::after {
  border-color: #a1c0d4 #a1c0d4 transparent transparent;
  border-style: solid;
  border-width: 15px;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 0;
}
.tile.__selected::before {
  color: #fff;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 10px;
  line-height: 0;
  position: absolute;
  right: 4px;
  top: 9px;
  z-index: 25;
}
.tile.__selected::after,
.tile.__selected::before {
  opacity: 1;
}
.__images-list .tile {
  line-height: 100px;
}
.tile.__brand .tile-descr {
  font-size: 24px;
  margin: 20px 0;
}
.tile.__brand .tile-image {
  display: inline-block;
  height: 120px;
  line-height: 96px;
  text-align: center;
  width: 120px;
}
.tile.__brand .tile-image img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}
.tile .tile-descr.__once {
  font-size: 24px;
  margin: 35px 0;
}
.tile .tile-ico + .tile-descr {
  margin: 0 10px;
}
.tile.double {
  font-size: 72px;
}
.tile.double .tile-descr.__once {
  margin: 100px 0 0;
}
.tile.double .tile-ico {
  margin: 60px 0 0;
}
.tile-double.wide {
  font-size: 48px;
}
.tile-double.wide .tile-descr.__once {
  margin: 35px 0;
}
.tile-double.wide .tile-ico {
  margin: 35px 10px 0 0;
}
.tile-double.wide .tile-descr {
  display: inline-block;
  vertical-align: 10px;
}
.tile-double.wide .tile-count + .tile-descr {
  display: block;
}
.__images-list .tile.double {
  line-height: 225px;
}
.tile.double.tall {
  font-size: 48px;
}
.tile.double.tall .tile-ico {
  margin: 80px 0 0;
}
.tile.quadro.__tree:hover {
  overflow-y: auto;
}
.list.__files,
.list.__items {
  margin: 0;
}
.list.__files {
  margin: 15px -20px;
}
.__items .list-group {
  background: #fbfdfe;
  border-bottom: 1px solid #dee9f0;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 50px 10px 20px;
  position: relative;
}
.__items .list-group::after {
  color: #a6a6a6;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.__items .list-item {
  background: #fff;
  border-bottom: 1px solid #dee9f0;
  cursor: pointer;
  display: block;
  padding: 5px;
  position: relative;
}
.__items .list-item.__hovered {
  background: #ecf7fc;
}
.__items .list-group + .list.__sub {
  display: none;
  margin-left: 15px;
}
.__items .list-group.__opened + .list.__sub {
  display: block;
}
.__items .list-item.__error {
  background: #fef8f7;
  border-color: #fce7e6;
}
.__items .list-item.__default {
  background: #ecf7fc;
}
.__items .list-item.__default::after {
  border-color: #a1c0d4 #a1c0d4 transparent transparent;
  border-style: solid;
  border-width: 15px;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: none;
  transform: none;
}
.__items .list-item.__default::before {
  color: #fff;
  font-size: 10px;
  line-height: 0;
  position: absolute;
  right: 4px;
  top: 9px;
  z-index: 25;
}
.__items .list-item:after {
  color: #a6a6a6;
  font-size: 16px;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: right 0.25s ease-in-out 0s;
  transition: right 0.25s ease-in-out 0s;
}
.__items.__reverse .list-item:after {
  right: 60px;
  content: "\f053";
}
.__items .list-item.__not-icon::after,
.__items .list-item.__not-icon::before {
  content: "";
}
.__items label.list-item:after {
  display: none;
}
.__items .list-item:hover:after {
  right: 25px;
}
.__items.__reverse .list-item:hover:after {
  right: 55px;
}
.__items .list-item.__default:hover:after {
  right: 0;
}
.__items .list-item:first-child {
  border-top: 1px solid #dee9f0;
}
.__items .list-item.dark:first-child {
  border-top: 1px solid #3d3d3d;
}
.__items .list-link {
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.__files .list-ico,
.__items .list-ico {
  font-size: 28px;
  height: 30px;
  line-height: 30px;
  margin: 0 5px 0 0;
  text-shadow: 1px 1px #fff;
  vertical-align: middle;
  width: 40px;
}
.__files .list-ico {
  font-size: 24px;
  width: 30px;
}
.__files .list.__sub .list-ico {
  font-size: 24px;
}
.__files .list-ico ~ .list-name,
.__items .list-ico ~ .list-name {
  vertical-align: middle;
}
.__files .list-name,
.__items .list-name {
  color: #4c4c4c;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  vertical-align: middle;
  z-index: 25;
}
.__items .list-img {
  background-blend-mode: darken;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 30px;
  margin: 0 15px 0 0;
  position: relative;
  vertical-align: middle;
  width: 30px;
}
.__files .list-name {
  vertical-align: middle;
}
.__files .list-descr {
  color: #4c4c4c;
  display: block;
  font-size: 12px;
}
.__files .list-ico ~ .list-descr,
.__files .list-img ~ .list-descr {
  margin: 0 0 0 37px;
}
.__items .list-t {
  display: block;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}
.__items .list-descr {
  color: #999;
  display: block;
  font-size: 12px;
}
.__items .list-action {
  display: inline-block;
  margin: -10px 10px -10px -10px;
  text-align: center;
  width: 28px;
}
.__items .list-action::after {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.__items .list-action .list-ico {
  color: #43b0e6;
  font-size: 21px;
  margin: 0;
  width: auto;
}
.__items .list-item:hover .list-action .list-ico {
  color: #43b0e6;
}
.__items .list-ico ~ .list-t {
  display: inline-block;
}
.__items .list-ico ~ .list-descr {
  margin: 0 0 0 50px;
}
.__items .list-descr,
.__items .list-t {
  position: relative;
  text-shadow: 1px 1px #fff;
  z-index: 50;
}
.__items .list-item input[type="checkbox"],
.__items .list-item input[type="radio"] {
  left: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
}
.__items .list-item .switch {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.__items label.list-item :checked ~ .switch,
.__items label.list-item.__selected .switch {
  background: #ecf7fc;
  box-shadow: 0 0 0 1px #a1c0d4;
}
.__items label.list-item :checked ~ .switch::after,
.__items label.list-item.__selected .switch::after {
  border-color: #a1c0d4 #a1c0d4 transparent transparent;
  border-style: solid;
  border-width: 15px;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 0;
}
.__items label.list-item :checked ~ .switch::before,
.__items label.list-item.__selected .switch::before {
  color: #fff;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 10px;
  line-height: 0;
  position: absolute;
  right: 4px;
  top: 9px;
  z-index: 25;
}
.__items .list-item.__selected .switch:after {
  border-color: #dee9f0 #dee9f0 transparent transparent;
}
.__items label.list-item.__selected .list-name {
  color: #a6a6a6;
}
.list.__chosen {
  margin: 10px 0;
}
.__chosen .list-item {
  margin: 0 10px 10px 0;
  position: relative;
}
.__chosen .list-name {
  background: #43b0e6;
  color: #fff;
  float: right;
  font-size: 11px;
  max-width: 345px;
  min-height: 15px;
  min-width: 30px;
  overflow: hidden;
  padding: 2px 5px;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
}
.__chosen .list-remove {
  color: #a1c0d4;
  cursor: pointer;
  float: left;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 17px;
  font-weight: 700;
  line-height: 1;
  margin: 3px 4px 0 0;
}
.__chosen .list-remove:hover {
  color: #43b0e6;
}
.__files .list-item {
  border: none;
  position: relative;
}
.__files .list-block {
  cursor: pointer;
  display: block;
  padding: 2px 20px;
}
.__files .list-link {
  display: inline-block;
  font-size: 14px;
  margin: 10px 0 0;
  padding: 2px 20px;
}
.__files .list-img {
  display: inline-block;
  height: 30px;
  margin: 0 5px 0 0;
  overflow: hidden;
  position: relative;
  top: 5px;
  vertical-align: middle;
  width: 30px;
}
.__files .list-img * {
  display: block;
  max-width: 100%;
}
.__files .list.__sub .list-item {
  margin: 0 0 0 40px;
}
.advancesearch {
  position: absolute;
  background: #e51400ed;
  list-style-type: none;
  right: 0;
  cursor: pointer;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
}
.pagination {
  border-width: 1px 0;
  display: block;
  margin: 2px;
  font-size: 12px;
}
.pagination .menu-item {
  text-align: center;
}
.pagination .menu-item + .menu-item {
  margin: 0 0 0 5px;
}
.pagination .menu-link {
  background: #fff;
  border: 1px solid #dee9f0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #4c4c4c;
  cursor: pointer;
  display: block;
  font-size: 12px;
  width: 100%;
  padding: 3px;
  margin: 3px;
}
.pagination .menu-link:hover {
  background: #e8f5fb;
}
.pagination .menu-item.__selected .menu-link {
  background: #43b0e6;
  color: #fff;
}
.pagination .menu-ico {
  font-size: 14px;
}
.logo {
  height: 100px;
  left: 50%;
  position: absolute;
  top: 20%;
  width: 300px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.logo-signup {
  height: 100px;
  left: 50%;
  position: absolute;
  top: 10%;
  width: 300px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.modal-backdrop.fade,
.modal.fade {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.modal-backdrop.fade {
  background: #000;
  opacity: 0.5;
}
.modal-content,
.window {
  background: #fff;
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.05);
  line-height: 1.3;
  min-height: 200px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 500px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-content,
.window-signup {
  background: #fff;
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.05);
  line-height: 1.3;
  min-height: 200px;
  left: 50%;
  position: absolute;
  top: 108%;
  width: 500px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-content,
.signupwindow {
  background: #fff;
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.05);
  line-height: 1.3;
  min-height: 200px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 500px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.__gallery .modal-dialog {
  background-color: #fff;
  border: 5px solid #a1c0d4;
  bottom: 40px;
  left: 80px;
  margin: 0;
  position: absolute;
  right: 80px;
  top: 40px;
  width: auto;
  -webkit-transform: none;
  transform: none;
}
.__gallery .modal-content {
  box-shadow: none;
  height: 100%;
  margin: 0;
  position: static;
  width: 100%;
  -webkit-transform: none;
  transform: none;
}
.__gallery .modal-content .modal-body {
  height: 100%;
  padding: 0;
}
.modal-content {
  margin: 30px 0;
  min-height: inherit;
}
.window .window-status {
  height: 40px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 25;
}
.window .window-status .progress-bar {
  height: 100%;
  margin: 0;
}

.modal-content .modal-header,
.window .window-head {
  background: #333;
  line-height: 36px;
  position: relative;
  z-index: 50;
}

.window-signup .window-head {
  background: #333;
  line-height: 36px;
  position: relative;
  z-index: 50;
}

.window .window-status ~ .window-head {
  background-color: transparent;
}
.modal-header .modal-title,
.window-head .window-t {
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
}
.modal-animate {
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
.modal-header .modal-title {
  font-size: 20px;
  font-weight: 400;
}
.window-head .__logo {
  display: inline;
  padding: 0 0 0 20px;
  max-height: 27px;
  max-width: 27px;
  vertical-align: middle;
}
.window-head .__logo ~ .window-t {
  padding: 0 20px 0 10px;
}
.window .window-cnt {
  padding: 0 20px;
}

.window-signup .window-cnt {
  padding: 0 20px;
}

.modal-content .modal-body,
.modal-content .modal-footer {
  font-size: 14px;
  padding: 20px;
}
.modal-content .modal-body .form-input {
  margin: 15px 0 0;
}
.modal-content .modal-footer {
  text-align: right;
}
.window-cnt .form-label {
  margin-left: 0;
}
.breadcrumbs {
  background-color: #3d3d3d;
  color: #fff;
  height: 20px;
  position: relative;
  font-size: 12px;
  left: 40px;
}
.navlink {
  font-size: 12px;
}
.breadcrumbs.long {
  -webkit-transition: left 0.25s ease-in-out 0s;
  transition: left 0.25s ease-in-out 0s;
  left: 225px;
}
.breadcrumbs .back {
  background: #fff;
  border: 1px solid #a1c0d4;
  border-radius: 50%;
  color: #a1c0d4;
  display: inline-block;
  height: 30px;
  line-height: 28px;
  text-align: center;
  vertical-align: top;
  width: 30px;
}
.breadcrumbs .back:hover {
  border-color: #43b0e6;
  color: #43b0e6;
}
.breadcrumbs .back ~ .menu {
  display: inline-block;
  margin: 4px 0 0 10px;
  vertical-align: top;
}
.breadcrumbs .menu-item.__divider {
  height: 22px;
  position: relative;
  width: 20px;
}
.breadcrumbs .menu-item.__divider::after {
  background: #a6a6a6;
  content: "";
  height: 4px;
  left: 50%;
  margin: 0 0 0 -2px;
  position: absolute;
  top: 50%;
  width: 4px;
}
.breadcrumbs .menu-link {
  max-width: 123px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #43b0e6;
  font-size: 14px;
}
.breadcrumbs .menu-link:hover {
  color: #52a2ca;
}
.tree {
  font-size: 0;
  text-align: center;
}
.tree-view {
  overflow-x: auto;
}
.tree-view ul {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.tree-scroll {
  padding: 20px 0;
  text-align: center;
}
.tree,
.tree-item {
  display: inline-block;
  padding: 0;
  position: relative;
  vertical-align: top;
}
.tree.__horizontal .tree-item {
  display: block;
}
.tree.__horizontal .tree-item:first-child::before {
  background: #fff;
  content: "";
  height: 50%;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 2px;
  z-index: 5;
}
.tree.__horizontal .tree-item:last-child::after {
  background: #fff;
  content: "";
  height: 50%;
  left: -1px;
  position: absolute;
  top: 51%;
  width: 2px;
  z-index: 5;
}
.tree.__horizontal .tree-item .tree-block {
  vertical-align: middle;
}
.tree.__horizontal .tree-item .tree-block::before {
  background: #a1c0d4;
  content: "";
  height: 2px;
  left: -14px;
  position: absolute;
  top: 50%;
  width: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.tree-item:first-child::before {
  background: #fff;
  content: "";
  height: 2px;
  left: -1px;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 5;
}
.tree-item:last-child::before {
  background: #fff;
  content: "";
  height: 2px;
  right: -1px;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 5;
}
.tree-block {
  border: 2px solid #a1c0d4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin: 10px 11px;
  padding: 5px 0;
  position: relative;
  vertical-align: top;
  width: 160px;
}
.tree-block:hover {
  background: #ecf7fc;
}
.tree-block::before {
  background: #a1c0d4;
  content: "";
  height: 10px;
  left: 50%;
  position: absolute;
  top: -12px;
  width: 2px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.tree-block.__canceled:hover {
  background: #faf1f1;
}
.tree-block.__selected {
  background: #d9effa;
  border-color: #a1c0d4;
}
.tree-block .inner {
  height: 100%;
  margin: 0 10px;
  text-align: left;
}
.tree-item.last .tree-block .inner::after {
  background: #fff;
  content: "";
  height: 2px;
  left: -1px;
  position: absolute;
  top: -12px;
  width: 50%;
  z-index: 5;
}
.tree-item.last .tree-block .inner::before {
  background: #fff;
  content: "";
  height: 2px;
  position: absolute;
  right: -1px;
  top: -12px;
  width: 50%;
  z-index: 10;
}
.first > :first-child::before {
  display: none;
}
.tree-node {
  position: relative;
}
.tree-node::after {
  background: #a1c0d4;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  right: 7px;
  top: 0;
}
.tree-node::before {
  background: #a1c0d4;
  content: "";
  height: 12px;
  left: 50%;
  position: absolute;
  top: -10px;
  width: 2px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.tree-node.__one-el {
  margin: -10px 0 0;
}
.tree-node.__one-el::after,
.tree-node.__one-el::before {
  display: none;
}
.tree.__horizontal .tree-node.__one-el {
  margin: 0 0 0 -10px;
}
.tree.__horizontal .tree-node {
  display: inline-block;
  vertical-align: middle;
}
.tree.__horizontal .tree-node .tree-item {
  text-align: left;
}
.tree.__horizontal .tree-node::after {
  background: #a1c0d4;
  bottom: 0;
  content: "";
  height: auto;
  left: -1px;
  position: absolute;
  top: 1px;
  width: 2px;
}
.tree.__horizontal .tree-node::before {
  background: #a1c0d4;
  content: "";
  height: 2px;
  left: -12px;
  position: absolute;
  top: 50%;
  width: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.tree-node.__empty::after {
  display: none;
}
.tree-el {
  color: #4c4c4c;
  display: block;
  font-size: 12px;
  line-height: 1;
  margin: 0;
  padding: 5px 0 5px 20px;
  position: relative;
  white-space: normal;
}
.tree-el .tree-ico {
  color: #4c4c4c;
  font-size: 14px;
  left: 0;
  position: absolute;
  width: 20px;
}
.tree-el.__canceled {
  background: #43b0e6;
  color: #fff;
  margin: 5px -10px -5px;
  padding: 5px 0;
  text-align: center;
}
.tree-el.__title {
  color: #43b0e6;
  height: 24px;
}
.columns {
  padding: 15px 0;
}
.form.columns {
  padding: 0;
}
.column:nth-last-child(2),
.column:nth-last-child(2) ~ .column {
  float: left;
  width: 50%;
}
.column:nth-last-child(3),
.column:nth-last-child(3) ~ .column {
  float: left;
  width: 33.33333%;
}
.column .list.__info {
  margin: 0;
}
.column .form-group {
  margin-right: 10px;
}
.column + .column .form-group {
  margin-left: 10px;
  margin-right: 0;
}
.column .column .form-group {
  margin-left: 0;
  margin-right: 0;
}
.column .column + .column .form-group {
  margin-left: 10px;
  margin-right: 0;
}
.column + .column .table-wrapper {
  margin-left: 10px;
}
.dropdown-item:hover {
  background: #ecf7fc;
}
.form-input.__calendar .dropdown-menu {
  background: #fff;
  border: 1px solid #dee9f0;
  margin: -1px 0 0;
  max-width: 400px;
  position: absolute;
  right: 0;
  z-index: 100;
}
.form-input.__calendar .dropdown-menu .btn {
  background: #fbfdfe;
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 400;
  position: static;
  text-shadow: none;
}
.form-input.__calendar .dropdown-menu .btn:disabled:hover {
  background: #fbfdfe;
}
.form-input.__calendar .dropdown-menu .btn:hover {
  background: #ecf7fc;
}
.form-input.__calendar .dropdown-menu table .btn {
  width: 100%;
}
.form-input.__calendar .dropdown-menu .btn-group .btn,
.form-input.__calendar .dropdown-menu .btn-group ~ .btn,
.form-input.__calendar .dropdown-menu .btn-success {
  background: #43b0e6;
  color: #fff;
  display: inline-block;
  padding: 0 10px;
}
.form-input.__calendar .dropdown-menu .btn-info,
.form-input.__calendar .dropdown-menu .btn.active {
  background: #43b0e6;
  color: #fff;
}
.text-muted {
  color: #999;
}
.dropdown-menu li {
  padding: 0;
  text-indent: 0;
}
.dropdown-menu li:last-child {
  background: #e8f5fb;
  overflow: hidden;
  padding: 5px !important;
}
.dropdown-menu li::before {
  content: "";
}
.dropdown-menu table {
  border-collapse: collapse;
  border-spacing: 0;
  padding: 0;
  width: 100%;
}
.dropdown-menu table td,
.dropdown-menu table th {
  background: #dee9f0;
  border-color: #fff;
  border-style: solid;
  border-width: 2px;
  font-size: 14px;
  padding: 1px;
  text-align: center;
  width: 12.5%;
}
.dropdown-menu table th {
  background: #dee9f0;
  font-weight: 500;
}
.dropdown-menu table th small {
  display: block;
  font-size: 13px;
  line-height: 1.4;
  margin: 5px 0;
}
.dropdown-menu .glyphicon {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-style: normal;
}
.dropdown-menu .glyphicon.glyphicon-chevron-left:before {
  content: "";
}
.dropdown-menu .glyphicon.glyphicon-chevron-right:before {
  content: "";
}
.select2-container {
  cursor: pointer;
  display: block;
}
.select2-container input {
  height: auto;
}
.select2-container .select2-choice,
.select2-container-multi .select2-choices {
  background: #fff;
  border-color: #dee9f0;
  color: #4c4c4c;
  font-size: 14px;
  line-height: 32px;
  min-height: 32px;
  padding: 0 10px;
}
.select2-container-multi .select2-choices {
  line-height: 32px;
  padding: 0 4px;
}
.select2-container .select2-choice,
.select2-drop {
  border-radius: 0;
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices,
.select2-container-multi.select2-container-active .select2-choices,
.select2-drop-active {
  border-color: #a1c0d4;
  box-shadow: none;
}
.form-input.__langs .select2-choice,
.form-input.__langs .select2-choices {
  padding-left: 36px;
}
.select2-choice > .select2-chosen {
  margin: 0;
}
.select2-container .select2-choice .select2-arrow {
  background: 0 0;
  border: none;
  width: 30px;
}
.select2-container .select2-choice .select2-arrow::after {
  border-color: #43b0e6 transparent transparent;
  border-style: solid;
  border-width: 5px 4px;
  content: "";
  margin: -3px 0 0;
  position: absolute;
  right: 10px;
  top: 50%;
}
.select2-dropdown-open .select2-choice .select2-arrow::after {
  border-color: transparent transparent #43b0e6;
  margin: -10px 0 0;
}
.select2-search input {
  background: #fff;
}
.select2-results .select2-highlighted {
  background: #43b0e6;
}
.select2-container li {
  color: #4c4c4c;
  padding: 0;
  text-indent: 0;
}
.select2-container li::before {
  content: "";
}
.form-input.__langs .select2-search-field {
  left: 36px;
}
.select2-container-multi .select2-choices .select2-search-choice {
  background: #fbfdfe;
  border: 1px solid #a1c0d4;
  border-radius: 0;
  box-shadow: none;
  font-size: 13px;
  line-height: 1;
  padding: 6px 30px 6px 5px;
  z-index: 49;
}
.select2-container-multi .select2-choices .select2-search-field input {
  padding: 7px 10px 7px 5px;
}
.select2-search-choice-close {
  left: auto !important;
  top: 6px;
}
.select2-container .select2-choice abbr {
  height: 100%;
  padding: 0 10px;
  right: 16px;
  top: 0;
}
.select2-search-choice-close::after {
  color: #43b0e6;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 12px;
  text-shadow: none;
  top: 0;
}
.select2-search-choice-close::after:hover::after {
  color: #43b0e6;
}
.select2-container-multi .select2-search-choice-close::after,
.select2-container-multi .select2-search-choice-close:hover::after {
  color: #a1c0d4;
}
tags-input .host {
  margin: 0;
}
tags-input .tags {
  border-color: #dee9f0;
  box-shadow: none;
  color: #4c4c4c;
  padding: 0 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
}
tags-input .tags li {
  padding: 0;
  text-indent: 0;
}
tags-input .tags li::before {
  content: "";
}
tags-input .tags .input {
  height: 28px;
  padding: 0;
}
tags-input .tags .tag-item {
  background: #43b0e6;
  border: none;
  border-radius: 0;
  color: #fff;
  font-size: 13px;
  height: 24px;
  margin: 4px 4px 0 0;
  padding: 0 30px 0 6px;
  position: relative;
}
tags-input .tags .tag-item.selected {
  background: #e51400;
}
tags-input .tags .tag-item .remove-button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 6px;
  top: 5px;
}
tags-input .tags.focused {
  border-color: #a1c0d4;
  box-shadow: none;
}
.form-input.__langs tags-input .tags {
  padding: 0 0 0 36px;
}
.cnt,
.nav-bar {
  bottom: 0;
  position: fixed;
  top: 0;
}
.nav-bar {
  left: 0;
  width: 225px;
  z-index: 100;
}
.nav-bar.__collapsed {
  width: 60px;
}
.nav-bar.__collapsed + .cnt {
  left: 60px;
}
.nav-bar.__collapsed .bar .info-license {
  font-size: 11px;
}
.nav-bar.__collapsed .bar .__items .list-item,
.nav-bar.__collapsed .bar .__items .list-item.__more,
.nav-bar.__collapsed .bar .__items .list-link {
  width: 32px;
}
.nav-bar.__collapsed .bar .__items .list-item.__more {
  padding: 10px 20px 10px 20px;
}
.nav-bar.__collapsed .bar .__items .list-ico {
  margin: 0;
}
.nav-bar.__collapsed .bar .__items .list-name {
  display: none;
}
.nav-bar.__collapsed .bar .__items .list-count {
  left: 44px;
}
.nav-bar.__collapsed .dropdown.__opened {
  -webkit-transform: translateX(72px);
  transform: translateX(72px);
}
.nav-bar .bar {
  background: #333;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.2) inset;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 50;
}
.nav-bar .bar .wrapper {
  height: 100%;
}
.nav-bar .bar .list {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.nav-bar .bar .list .outer-wrapper {
  height: 100%;
}
.nav-bar .bar .list .inner-wrapper {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  scrollbar-arrow-color: #545454;
  -ms-scrollbar-arrow-color: #545454;
  scrollbar-face-color: #434343;
  -ms-scrollbar-face-color: #434343;
  scrollbar-shadow-color: #434343;
  -ms-scrollbar-shadow-color: #434343;
  scrollbar-track-color: #545454;
  -ms-scrollbar-track-color: #545454;
}
.nav-bar .bar .list .inner-wrapper:hover {
  overflow-y: auto;
}
.nav-bar .bar .list::-webkit-scrollbar {
  height: 2px;
  width: 2px;
}
.nav-bar .bar .list .inner-wrapper::-webkit-scrollbar {
  background: 0 0;
}
.nav-bar .bar .list .inner-wrapper::-webkit-scrollbar-track-piece {
  background: #545454;
}
.nav-bar .bar .list .inner-wrapper::-webkit-scrollbar-thumb {
  background: #434343;
}
.nav-bar .bar .list .inner-wrapper .content {
  position: absolute;
  top: 0;
  bottom: 0;
}
.nav-bar .bar .__items .list-item {
  background: #333;
  border: none;
  width: 100%;
}
.nav-bar .bar .__items .list-item:not(.__more):after {
  display: none;
}
.nav-bar .bar .__items .list-item.__hovered {
  background: #333;
}
.nav-bar .bar .__items .list-item.__animated {
  -webkit-animation: notify ease 1 1.5s;
  animation: notify ease 1 1.5s;
}
.nav-bar .bar .__items .list-item.__animated .list-ico,
.nav-bar .bar .__items .list-item.__animated:hover .list-ico {
  -webkit-animation: bell ease-in-out 1s 0.5s;
  animation: bell ease-in-out 1s 0.5s;
}
.nav-bar .bar .__items .list-item.__animated .list-name,
.nav-bar .bar .__items .list-item.__animated .list-t,
.nav-bar .bar .__items .list-item.__animated:hover .list-name,
.nav-bar .bar .__items .list-item.__animated:hover .list-t {
  color: #fff;
  text-shadow: none;
}
.nav-bar .bar .__items .list-item.__draggable:before {
  color: #a6a6a6;
  content: "\f142";
  cursor: move;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 24px;
  opacity: 0;
  display: none;
  position: absolute;
  left: 0;
  top: 50%;
  padding: 7px;
  width: 6px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity 0.25s ease-in-out 0s;
  transition: opacity 0.25s ease-in-out 0s;
}
.nav-bar .bar .__items .list-item.__draggable:hover:before {
  opacity: 1;
  display: block;
}
.nav-bar .bar .__items .list-item.__more {
  height: 32px;
  width: 165px;
}
.nav-bar .bar .__items .list-item.__more .list-ico {
  margin: 0;
}
.nav-bar .bar .__items .list-ico {
  font-size: 25px;
  text-shadow: 1px 1px #333;
  width: 32px;
}
.nav-bar .bar .__items .list-name,
.nav-bar .bar .__items .list-t {
  color: #c2c2c2;
}
.nav-bar .bar .__items .list-descr,
.nav-bar .bar .__items .list-item:hover .list-name,
.nav-bar .bar .__items .list-t {
  text-shadow: 1px 1px #333;
}
.nav-bar .bar .__items .list-count {
  background: #fff;
  color: #333;
  height: 14px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  min-width: 14px;
  position: absolute;
  left: 42px;
  top: 8px;
  border-radius: 50%;
}
.nav-bar .bar .divider {
  border-bottom: 1px solid #606060;
  height: 0;
  margin: 0 20px;
}
.nav-bar .bar .info {
  background: #333;
  font-size: 0;
  text-align: center;
  padding: 5px 0 0 0;
  z-index: 50;
}
.nav-bar .bar .info-version {
  color: #c2c2c2;
  display: block;
  font-size: 12px;
  padding: 0 0 5px 0;
}
.nav-bar .bar .info-license {
  color: #43b0e6;
}
.nav-bar .bar .info-license.__valid {
  color: #008a00;
}
.nav-bar .menu-item {
  text-align: center;
}
.nav-bar .menu-link {
  color: #c2c2c2;
  display: block;
  font-size: 13px;
  padding: 15px 0;
  position: relative;
}
.nav-bar .menu-item.__done {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.nav-bar .menu-item.__done .menu-link {
  background: #43b0e6;
  color: #fff;
  cursor: pointer;
}
.nav-bar .menu-link:hover {
  color: #43b0e6;
}
.nav-bar .__selected .menu-link {
  background-color: #383838;
  color: #43b0e6;
}
.nav-bar .menu-ico {
  display: block;
  font-size: 24px;
  height: 20px;
  margin: 0 0 10px;
}
.nav-bar .dropdown {
  background: #383838;
  bottom: 0;
  box-shadow: 5px 0 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 360px;
  z-index: 25;
  -webkit-transform: translateX(-360px);
  transform: translateX(-360px);
}
.nav-bar .dropdown.__more {
  background: #fff;
}
.nav-bar .dropdown.__opened {
  -webkit-transform: translateX(235px);
  transform: translateX(235px);
}
.dropdown .dropdown-close {
  color: #c2c2c2;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 15px;
  top: 30px;
}
.dropdown .dropdown-close:hover {
  color: #fff;
}
.dropdown.__more .dropdown-close {
  color: #a6a6a6;
  top: 10px;
  right: 10px;
}
.dropdown.__more .dropdown-close:hover {
  color: #333;
}
.dropdown .dropdown-head {
  padding: 85px 20px 14px;
  text-align: right;
}
.dropdown.__more .dropdown-head {
  background: #fbfdfe;
  border-color: #dee9f0;
  border-style: solid;
  border-width: 0 0 2px;
  text-align: left;
  padding: 0;
  height: 100px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dropdown.__more .dropdown-head .form,
.dropdown.__more .dropdown-head .form-group {
  margin: 0 20px;
  padding: 17px 0;
  position: relative;
}
.dropdown.__more .dropdown-head .form-group .form-hint {
  padding: 5px 0;
}
.dropdown .menu-item {
  margin: 0 0 0 20px;
  text-align: left;
}
.dropdown .menu-link {
  cursor: pointer;
  font-size: 14px;
}
.dropdown .menu-link:hover {
  color: #fff;
}
.dropdown .menu-ico {
  display: inline-block;
  font-size: 18px;
  margin: 0 5px 0 0;
}
.dropdown .dropdown-content {
  height: -webkit-calc(100% - 149px);
  height: calc(100% - 149px);
  padding: 0 20px;
  overflow: hidden;
}
.dropdown .dropdown-content:hover {
  overflow-y: auto;
}
.dropdown.__more .dropdown-content {
  height: -webkit-calc(100% - 100px);
  height: calc(100% - 100px);
  scrollbar-arrow-color: #f9f9f9;
  -ms-scrollbar-arrow-color: #f9f9f9;
  scrollbar-face-color: #e6e6e6;
  -ms-scrollbar-face-color: #e6e6e6;
  scrollbar-shadow-color: #e6e6e6;
  -ms-scrollbar-shadow-color: #e6e6e6;
  scrollbar-track-color: #f9f9f9;
  -ms-scrollbar-track-color: #f9f9f9;
}
.dropdown.__more .dropdown-content::-webkit-scrollbar-track-piece {
  background: #f9f9f9;
}
.dropdown.__more .dropdown-content::-webkit-scrollbar-thumb {
  background: #e6e6e6;
}
.dropdown.__more .list {
  width: 360px;
}
.dropdown.__more .list-group {
  padding: 10px 60px 10px 20px;
}
.dropdown.__more .list-group:after {
  right: 32px;
}
.dropdown.__more .list-item {
  padding: 10px 20px;
}
.dropdown.__more .list-item:after {
  display: none;
}
.dropdown.__more .list-ico {
  font-size: 25px;
  width: 32px;
}
.dropdown.__more .list-fav {
  color: #a6a6a6;
  display: block;
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 10px 32px 10px 12px;
}
.dropdown.__more .list-fav .list-ico {
  font-size: 16px;
  text-align: center;
  margin: 0;
  top: 0;
  width: 16px;
}
.dropdown:not(.__more) .dropdown-content {
  scrollbar-arrow-color: #545454;
  -ms-scrollbar-arrow-color: #545454;
  scrollbar-face-color: #434343;
  -ms-scrollbar-face-color: #434343;
  scrollbar-shadow-color: #434343;
  -ms-scrollbar-shadow-color: #434343;
  scrollbar-track-color: #545454;
  -ms-scrollbar-track-color: #545454;
}
.dropdown:not(.__more) .dropdown-content .list {
  width: 315px;
}
.dropdown:not(.__more) .dropdown-content .list-item {
  border-top: 1px solid #606060;
}
.dropdown:not(.__more) .dropdown-content .list-link {
  color: #c2c2c2;
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  padding: 20px;
  text-transform: uppercase;
}
.dropdown:not(.__more) .dropdown-content .list-link:hover {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.dropdown:not(.__more) .dropdown-content .__selected .list-link {
  background: 0 0;
  color: #43b0e6;
}
.dropdown:not(.__more) .dropdown-content .list-ico {
  font-size: 25px;
  height: 24px;
  margin: 0 20px 0 0;
  position: relative;
  top: 2px;
  width: 32px;
}
.dropdown:not(.__more) .dropdown-content .info .list-ico {
  color: #43b0e6;
}
.dropdown:not(.__more) .dropdown-content .error .list-ico {
  color: #e51400;
}
.dropdown:not(.__more) .dropdown-content .warning .list-ico {
  color: #f0a30a;
}
.dropdown:not(.__more) .dropdown-content .error .list-ico::after,
.dropdown:not(.__more) .dropdown-content .warning .list-ico::after {
  background: #fff;
  content: "";
  height: 12px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 3px;
  z-index: -1;
}
.dropdown::-webkit-scrollbar {
  background-color: transparent;
}
.dropdown::-webkit-scrollbar-track-piece {
  background: #545454;
}
.dropdown::-webkit-scrollbar-thumb {
  background: #434343;
}
.dropdown .list-date,
.dropdown .list-descr,
.dropdown .list-t {
  color: #fff;
  text-transform: none;
}
.dropdown .list-date,
.dropdown .list-descr {
  display: block;
  font-size: 12px;
}
.dropdown .list-t {
  font-size: 14px;
}
.dropdown .list-descr {
  display: block;
  margin: 0 0 0 55px;
}
.dropdown .list-date {
  color: #c2c2c2;
  margin: 5px 0 0 55px;
  padding: 0 0 0 25px;
  position: relative;
}
.dropdown .list-date::after {
  color: #c2c2c2;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 0;
}
.dropdown .list-bar {
  background-color: #43b0e6;
  display: block;
  height: 10px;
  margin-left: 55px;
}
.cnt {
  left: 225px;
  overflow: hidden;
  overflow-x: auto;
  right: 0;
  white-space: nowrap;
  z-index: 50;
}
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.cnt .cnt-inner {
  display: inline-block;
  height: 100%;
  word-spacing: -4px;
  width: 100%;
}
.cnt .cnt-inner .blade:not(:first-child):last-child {
  margin-right: 90px;
}
.cnt .dashboard.black {
  color: #fff;
  background-color: #232323;
}
.cnt .blade,
.cnt .dashboard {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  white-space: nowrap;
  word-spacing: 0;
}
.cnt.__hidden {
  overflow-x: hidden;
}
.blade {
  background: #fff;
  box-shadow: -5px 0 5px -6px rgba(0, 0, 0, 0.1);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  overflow: hidden;
  position: relative;
}
.blade.__animated {
  z-index: -1;
  -webkit-animation: blade ease-in-out 0.25s;
  animation: blade ease-in-out 0.25s;
}
.blade.__animated:nth-child(2) {
  z-index: -2;
}
.blade.__animated:nth-child(3) {
  z-index: -3;
}
.blade.__animated:nth-child(4) {
  z-index: -4;
}
.blade.__animated:nth-child(5) {
  z-index: -5;
}
.blade.__animated.__closing {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  -webkit-animation-duration: 0.125s;
  animation-duration: 0.125s;
}
.blade .blade-container,
.blade .blade-head {
  box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.2) inset;
}
.blade .blade-head {
  background: #333;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 90px;
  padding: 25px 0 5px;
  position: relative;
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.blade .blade-head .blade-status {
  height: 25px;
  left: 0;
  position: absolute;
  right: 8px;
  top: 0;
}
.blade .blade-head .blade-status .status-message {
  background: #7a1c13;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  left: 0;
  line-height: 23px;
  padding: 0 0 0 50px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 300;
}
.blade .blade-head .blade-status .status-message::after {
  color: #fff;
  content: "";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  left: 20px;
  position: absolute;
  top: 2px;
}
.blade .blade-head .blade-status .progress-bar {
  bottom: 0;
  height: auto;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 200;
}
.blade .blade-head .blade-nav {
  height: 25px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.blade .blade-head .blade-nav .menu {
  position: relative;
}
.blade .blade-head .blade-nav .menu-item {
  background: 0 0;
  border: none;
  cursor: pointer;
  font-size: 0;
  height: 25px;
  line-height: 1.4;
  position: relative;
  width: 35px;
  z-index: 250;
}
.blade .blade-head .blade-nav .menu-item * {
  background: 0 0;
  border: none;
  cursor: pointer;
  font-size: 0;
  height: 100%;
  width: 100%;
}
.blade .blade-head .blade-nav .menu-item .btn-ico {
  color: #fff;
  font-size: 13px;
  line-height: 0;
  margin: 12px 0 0;
}
.blade .blade-head .blade-nav .menu-item.__close,
.blade .blade-head .blade-nav .menu-item.__maximize,
.blade .blade-head .blade-nav .menu-item.__minimize {
  z-index: 350;
}
.blade .blade-head .blade-nav .menu-item.__disabled {
  cursor: not-allowed;
}
.blade .blade-head .blade-nav .menu-item.__maximize::after,
.blade .blade-head .blade-nav .menu-item.__minimize::after {
  border-color: #fff;
  border-style: solid;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 8px;
  top: 8px;
  width: 12px;
}
.blade .blade-head .blade-nav .menu-item.__minimize::after {
  border-width: 0 0 2px;
}
.blade .blade-head .blade-nav .menu-item.__maximize::after {
  border-width: 2px 1px 1px;
}
.blade .blade-head .blade-nav .menu-item.__close::after {
  content: "×";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 20px;
  font-weight: 700;
  top: -3px;
}
.blade .blade-head .blade-nav .menu-item::after {
  color: #fff;
  content: "";
  left: 50%;
  margin: 0 0 0 -6px;
  position: absolute;
}
.blade .blade-head .blade-nav .menu-item:hover {
  background: #43b0e6;
}
.blade .blade-head .blade-nav .menu-item.__close:hover {
  background: #e51400;
}
.blade .blade-head .blade-nav .menu-item.__disabled:hover {
  background: 0 0;
}
.blade .blade-head .blade-nav .menu-item > :disabled {
  cursor: not-allowed;
}
.blade .blade-head .blade-t {
  color: #fff;
  left: 0;
  margin: 10px 20px;
  min-height: 34px;
  padding: 0 0 0 40px;
  position: absolute;
  right: 8px;
}
.blade .blade-head .blade-t .blade-t_head {
  font-size: 18px;
  margin: 6px 0 0;
}
.blade .blade-head .blade-t_ico {
  font-size: 34px;
  line-height: 34px;
  height: 34px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}
.blade .blade-head .blade-t_head,
.blade .blade-head .blade-t_subhead {
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.blade .blade-head .blade-t_subhead {
  color: #bbb;
  font-size: 11px;
}
.blade .blade-head .blade-t.__secondary .blade-t_head {
  font-size: 14px;
  margin: 0;
}
.blade .blade-head .blade-toolbar {
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
  box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.2) inset;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  margin-top: 30px;
  height: 35px;
  z-index: 50;
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.blade .blade-head .blade-toolbar .menu {
  white-space: normal;
}
.blade .blade-head .blade-toolbar .menu-item {
  margin: 2px 0;
}
.blade .blade-head .blade-toolbar .menu-item.__loading {
  pointer-events: none;
  position: relative;
}
.blade .blade-head .blade-toolbar .menu-item.__loading::after {
  background-color: #333;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 30px 30px;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-animation: linear 2s infinite progress;
  animation: linear 2s infinite progress;
}
.blade .blade-head .blade-toolbar .menu-btn {
  background: 0 0;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  font-weight: 300;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  text-align: center;
}
.blade .blade-head .blade-toolbar .menu-btn:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.blade .blade-head .blade-toolbar .menu-btn:disabled {
  color: #fff;
  cursor: not-allowed;
}
.blade .blade-head .blade-toolbar .menu-ico {
  display: block;
  font-size: 18px;
  margin: 0 0 3px;
}
.blade .blade-head .blade-switch {
  bottom: 4px;
  position: absolute;
  right: 28px;
  text-align: center;
  z-index: 100;
}
.blade .blade-head .blade-switch .switch-t {
  color: #fff;
  display: block;
  font-size: 11px;
  font-weight: 300;
  margin: 0 0 3px;
  text-transform: uppercase;
}
.blade .blade-head .blade-switch .form-control .form-label {
  margin: 0;
}
.blade.__animate {
  z-index: -1;
}
.blade.__active {
  margin: 0 0 0 40px;
}
.blade.__active .blade-head {
  background: #34454c;
}
.menu.__more .menu-item {
  margin: 5px 0;
}
.menu.__more .blade-switch {
  padding: 0 10px;
  position: static;
}
.blade .blade-container,
.blade .blade-head {
  padding-right: 2px;
}
.blade .blade-container,
.blade .blade-content,
.blade .blade-inner,
.blade .blade-wrapper {
  height: 100%;
}
.blade .blade-container {
  height: -webkit-calc(100% - 95px);
  height: calc(100% - 95px);
  overflow: hidden;
  position: relative;
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.blade .blade-container .blade-static {
  background: #fbfdfe;
  border-color: #dee9f0;
  border-style: solid;
  border-width: 0 0 2px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 70px;
}
.blade .blade-container .blade-static.__quote {
  height: 230px;
}
.blade .blade-container .blade-static.__collapsed .breadcrumbs {
  padding: 13px 20px;
}
.blade .blade-container .blade-static.__expanded .breadcrumbs {
  margin: 0 0 -15px;
  padding: 14px 20px;
}
.blade .blade-container .blade-static .form,
.blade .blade-container .blade-static .form-group {
  margin: 0 20px;
  padding: 17px 0;
  position: relative;
}
.blade .blade-container .blade-static .form-group:only-child {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
}
.blade .blade-container .blade-static .column .form-group {
  margin: 5px 10px 0 0;
  padding: 0;
}
.blade .blade-container .blade-static .form-group .link {
  display: inline-block;
  font-size: 14px;
  margin: 12px 10px 0 0;
}
.blade .blade-container .blade-static .form-input.__search {
  margin-right: 80px;
}
.blade .blade-container .blade-static .form-input.__search:last-child {
  margin-right: 0;
}
.blade .blade-container .blade-static .form-count {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}
.blade .blade-container .blade-static .pagination {
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.blade .blade-container .blade-static .btn + .btn {
  margin: 0 0 0 15px;
}
.blade .blade-container .blade-static.__expanded {
  height: 114px;
}
.blade .blade-container .blade-static.__collapsed {
  height: 60px;
}
.blade .blade-container .blade-static.__bottom {
  border-width: 2px 0 0;
  bottom: 0;
  left: 0;
  padding: 18px 20px;
  position: absolute;
  right: 8px;
  z-index: 100;
}
.blade .blade-container .blade-static.__bottom .form-label {
  margin: 0 0 5px;
}
.blade .blade-container .blade-static + .blade-content {
  height: -webkit-calc(100% - 72px);
  height: calc(100% - 72px);
}
.blade .blade-container .blade-static.__quote + .blade-content {
  height: -webkit-calc(100% - 230px);
  height: calc(100% - 230px);
}
.blade .blade-container .blade-static.__collapsed + .blade-content {
  height: -webkit-calc(100% - 62px);
  height: calc(100% - 62px);
}
.blade .blade-container .blade-static.__expanded + .blade-content {
  height: -webkit-calc(100% - 116px);
  height: calc(100% - 116px);
}
.blade
  .blade-container
  .blade-static
  + .blade-static.__bottom
  + .blade-content {
  height: -webkit-calc(100% - 140px);
  height: calc(100% - 140px);
}
.blade
  .blade-container
  .blade-static
  + .blade-static.__bottom.__collapsed
  + .blade-content,
.blade
  .blade-container
  .blade-static.__collapsed
  + .blade-static.__bottom
  + .blade-content {
  height: -webkit-calc(100% - 130px);
  height: calc(100% - 130px);
}
.blade
  .blade-container
  .blade-static.__collapsed
  + .blade-static.__bottom.__collapsed
  + .blade-content {
  height: -webkit-calc(100% - 120px);
  height: calc(100% - 120px);
}
.blade
  .blade-container
  .blade-static
  + .blade-static.__bottom.__expanded
  + .blade-content,
.blade
  .blade-container
  .blade-static.__expanded
  + .blade-static.__bottom
  + .blade-content {
  height: -webkit-calc(100% - 184px);
  height: calc(100% - 184px);
}
.blade
  .blade-container
  .blade-static.__expanded
  + .blade-static.__bottom.__expanded
  + .blade-content {
  height: -webkit-calc(100% - 228px);
  height: calc(100% - 228px);
}
.blade
  .blade-container
  .blade-static.__expanded
  + .blade-static.__bottom.__collapsed
  + .blade-content {
  height: -webkit-calc(100% - 174px);
  height: calc(100% - 174px);
}
.blade .blade-container .blade-container .canceled + .blade-content {
  height: -webkit-calc(100% - 45px);
  height: calc(100% - 45px);
}
.blade .blade-container.__loading {
  opacity: 0.25;
  pointer-events: none;
}
.blade .blade-content {
  white-space: normal;
}
.blade.__active .blade-content {
  background: #f1fbfe;
}
.blade .blade-content.__gray {
  background: #fbfdfe;
}
.__canceled {
  background-color: #eff7fc;
}
.blade .blade-content.__small-narrow {
  min-width: 290px;
  width: 290px;
}
.blade .blade-content.__medium-wide {
  min-width: 550px;
  width: 550px;
}
.blade .blade-content.__large-wide {
  min-width: 680px;
  width: 680px;
}
.blade .blade-content.__xlarge-wide {
  min-width: 810px;
  width: 810px;
}
.blade .blade-content.__xxlarge-wide {
  min-width: 940px;
  width: 940px;
}
.blade.__expanded .blade-content,
.blade.__maximized .blade-content {
  width: 100%;
}
.blade .blade-content.__tree {
  width: 340px;
}
.blade-content .table {
  min-width: auto;
}
.blade-content.__medium-wide .table {
  min-width: 550px;
}
.blade .blade-inner {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  padding: 0 20px;
  position: relative;
}
.blade .blade-inner::-webkit-scrollbar,
.log::-webkit-scrollbar,
.ui-grid-viewport::-webkit-scrollbar {
  background-color: transparent;
}
.blade .blade-inner::-webkit-scrollbar-track-piece,
.log::-webkit-scrollbar-track-piece {
  background: #f9f9f9;
}
.blade .blade-inner::-webkit-scrollbar-thumb,
.log::-webkit-scrollbar-thumb {
  background: #e6e6e6;
}
.blade .__gray .blade-inner::-webkit-scrollbar-track-piece,
.ui-grid-viewport::-webkit-scrollbar-track-piece {
  background: #e9e9e9;
}
.blade .blade-inner,
.log {
  scrollbar-arrow-color: #f9f9f9;
  -ms-scrollbar-arrow-color: #f9f9f9;
  scrollbar-face-color: #e6e6e6;
  -ms-scrollbar-face-color: #e6e6e6;
  scrollbar-shadow-color: #e6e6e6;
  -ms-scrollbar-shadow-color: #e6e6e6;
  scrollbar-track-color: #f9f9f9;
  -ms-scrollbar-track-color: #f9f9f9;
}
.blade .__gray .blade-inner,
.ui-grid-viewport {
  scrollbar-arrow-color: #e9e9e9;
  -ms-scrollbar-arrow-color: #e9e9e9;
  scrollbar-face-color: #d6d6d6;
  -ms-scrollbar-face-color: #d6d6d6;
  scrollbar-shadow-color: #d6d6d6;
  -ms-scrollbar-shadow-color: #d6d6d6;
  scrollbar-track-color: #e9e9e9;
  -ms-scrollbar-track-color: #e9e9e9;
}
.blade .blade-inner:hover,
.blade.__expanded .blade-inner,
.blade.__maximized .blade-inner {
  overflow-y: auto;
}
.blade .blade-inner.ui-grid-no-scroll {
  overflow: visible;
}
.blade-content.__small-narrow .inner-block {
  min-width: 250px;
  width: 250px;
}
.blade-content.__medium-wide .inner-block {
  min-width: 510px;
  width: 510px;
}
.blade-content.__large-wide .inner-block {
  min-width: 640px;
  width: 640px;
}
.blade-content.__xlarge-wide .inner-block {
  min-width: 770px;
  width: 770px;
}
.blade-content.__xxlarge-wide .inner-block {
  min-width: 900px;
  width: 900px;
}
.blade.__expanded .blade-content .inner-block,
.blade.__maximized .blade-content .inner-block {
  width: 100%;
}
.blade-content.__tree .inner-block {
  width: 300px;
}
.blade .blade-loader {
  padding: 20px;
  text-align: center;
}
.blade .blade-loader::after {
  color: #43b0e6;
  content: attr(data-title);
  font-size: 60px;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  white-space: nowrap;
  -webkit-animation: loading 2s infinite ease-in-out;
  animation: loading 2s infinite ease-in-out;
}
.blade .example-mail {
  padding: 20px 0;
}
.image-preview {
  background: #fff;
  border: 2px solid #a1c0d4;
  height: 300px;
  line-height: 298px;
  overflow: hidden;
  position: fixed;
  width: 300px;
  z-index: 50;
}
.image-preview * {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}
.image-preview-small {
  background: #fff;
  border: 2px solid #a1c0d4;
  height: 108px;
  width: 108px;
  line-height: 100px;
  overflow: hidden;
  z-index: 50;
}
.image-preview-small * {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}
.image-preview-no-image {
  text-align: center;
  font-size: 48px;
  color: #dee9f0;
}
.customize .custom-ico {
  color: #fff;
  font-size: 12px;
}
.tooltip {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 12px;
  position: relative;
  z-index: 100;
  opacity: 1;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #43b0e6;
  color: #fff;
  text-align: center;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #000 transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltip-arrow {
  border-color: transparent;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
}
.tooltip.left,
.tooltip.right {
  padding: 0 8px;
}
.tooltip.left .tooltip-arrow,
.tooltip.right .tooltip-arrow {
  top: 50%;
  margin-top: -8px;
}
.tooltip.left {
  margin-left: -3px;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #43b0e6;
  border-width: 8px 0 8px 8px;
  right: 0;
}
.tooltip.right {
  margin-left: 3px;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #43b0e6;
  border-width: 8px 8px 8px 0;
  left: 0;
}
.tooltip.bottom,
.tooltip.top {
  padding: 8px 0;
}
.tooltip.bottom .tooltip-arrow,
.tooltip.top .tooltip-arrow {
  left: 50%;
  margin-left: -8px;
}
.tooltip.top {
  margin-top: -3px;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #43b0e6;
  border-width: 8px 8px 0 8px;
  bottom: 0;
}
.tooltip.bottom {
  margin-top: 3px;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #43b0e6;
  border-width: 0 8px 8px 8px;
  top: 0;
}
.tooltip .tooltip-inner {
  background: #43b0e6;
  color: #fff;
  padding: 10px;
  max-width: 600px;
}
.__informer {
  background: #ecf7fc;
  box-shadow: 0 0 0 2px #dee9f0 inset;
  color: #a1c0d4;
  font-size: 22px;
  padding: 10px;
}
.__informer::after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.__informer * {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle;
}
.iframe-cnt {
  bottom: 20px;
  left: 20px;
  position: absolute;
  right: 280px;
  top: 20px;
}
.lookbook {
  background: #fff;
  bottom: 20px;
  position: absolute;
  right: 28px;
  top: 20px;
  width: 240px;
}
.tabs-elements {
  margin: 0 -20px;
  padding: 18px 0 0;
  overflow: hidden;
  position: relative;
}
.tabs .tab-item {
  background: #dee9f0;
  border-top: 1px solid #dee9f0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #43b0e6;
  cursor: pointer;
  float: left;
  font-size: 14px;
  font-weight: 600;
  height: 34px;
  line-height: 26px;
  position: relative;
  top: 2px;
  text-align: center;
}
.tabs .tab-item + .tab-item {
  border-left: 1px solid #fff;
}
.tabs .tab-item.__selected {
  background: #fff;
  border-bottom: 2px solid #fff;
  line-height: 30px;
  top: 0;
}
.tabs .tab-item:nth-last-child(2),
.tabs .tab-item:nth-last-child(2) ~ .tab-item {
  width: 50%;
}
.tabs .tab-item:nth-last-child(3),
.tabs .tab-item:nth-last-child(3) ~ .tab-item {
  width: 33.3333%;
}
.tabs .tab-item:nth-last-child(4),
.tabs .tab-item:nth-last-child(4) ~ .tab-item {
  width: 25%;
}
.tabs .tab-item:nth-last-child(5),
.tabs .tab-item:nth-last-child(5) ~ .tab-item {
  width: 20%;
}
.tabs .tab-cnt {
  clear: both;
  display: none;
  padding: 10px 0;
}
.tabs .tab-cnt.__opened {
  display: block;
}
.tab-cnt h1,
.tab-cnt h2,
.tab-cnt h3 {
  margin: 20px 0;
}
.tab-cnt ol,
.tab-cnt p,
.tab-cnt ul {
  margin: 0 0 10px;
}
.tab-cnt img {
  margin: 10px 0;
}
.overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 9000;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.gallery-tpl {
  display: none;
}
.overlay.__loaded {
  background: rgba(0, 0, 0, 0.7);
}
.overlay .overlay-cnt {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.overlay .overlay-cnt::after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.overlay .popup {
  background: #fff;
  border: 5px solid #a1c0d4;
  bottom: 40px;
  left: 80px;
  position: absolute;
  right: 80px;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  top: 40px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.popup .popup-cnt {
  bottom: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}
.modal-body .popup-close {
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 50;
}
.modal-body.size-clearfix {
  max-height: 530px;
  overflow: auto;
}
.modal-body .item-list-clearfix {
  max-height: 530px;
}
.modal-body .gallery {
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.gallery .image-link {
  background: #a1c0d4;
  color: #fff;
  left: 0;
  height: 45px;
  line-height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 25;
}
.gallery .__slides,
.gallery .__slides .list-item {
  height: 100%;
  width: 100%;
}
.gallery .control {
  color: #a1c0d4;
  font-size: 30px;
  left: -50px;
  position: absolute;
  text-align: center;
  top: 50%;
  z-index: 5;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.35s ease;
  transition: 0.35s ease;
}
.gallery .control::after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.gallery .control:hover {
  color: #43b0e6;
}
.gallery:hover .ctrl-l {
  left: 20px;
}
.gallery .ctrl-r {
  left: auto;
  right: 20px;
}
.gallery:hover .ctrl-r {
  right: 20px;
}
.gallery .__slides {
  padding: 55px 0 10px;
  position: relative;
}
.__slides .list-item {
  float: left;
}
.__slides .list-item::after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.__slides .list-img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}
.gallery .__navs {
  bottom: -100px;
  height: 50px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: 0.35s ease;
  transition: 0.35s ease;
}
.gallery:hover .__navs {
  bottom: 20px;
}
.__navs .list-item {
  background: #fff;
  border: 1px solid #dee9f0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  margin: 0 4px;
  opacity: 0.5;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: 50px;
}
.__navs .list-item.__selected,
.__navs .list-item:hover {
  border-color: #a1c0d4;
  opacity: 1;
}
.__navs .list-item::after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.__navs .list-item .list-img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}
.drag-area {
  background: #dee9f0;
  border: 2px dashed #a1c0d4;
  height: 100px;
  margin: 0 0 20px;
  padding: 0 20px;
  text-align: center;
}
.drag-area::after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.drag-area.bd-blue {
  background: #d2e0e8;
}
.drag-content {
  color: #a1c0d4;
  display: inline-block;
  font-size: 18px;
  text-shadow: 1px 1px 1px #fff;
  vertical-align: middle;
}
.dashboard {
  background: #eef5f7;
  position: relative;
  z-index: 50;
}
.dashboard .dashboard-head {
  height: 80px;
  position: relative;
}
.dashboard .dashboard-area {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: -webkit-calc(100% - 100px);
  height: calc(100% - 100px);
  margin: 0 3px 0 0;
  overflow: hidden;
  position: relative;
  width: 950px;
}
.dashboard .dashboard-area:hover .dashboard-inner {
  overflow: auto;
}
.dashboard .dashboard-inner {
  height: -webkit-calc(100% - 22px);
  height: calc(100% - 22px);
  padding: 22px 20px 0;
  position: relative;
}
.dashboard-grid {
  background: #fff;
  margin: 10px;
}
.dashboard-grid.black {
  background: #333;
  color: #fff;
  margin: 10px;
}
.dashboard .dashboard-inner .gridster-loaded {
  width: 770px;
}
.dashboard .dashboard-account.black {
  color: #fff;
  background-color: #333;
  border: 2px solid #333;
}
.dashboard .dashboard-account.black:hover {
  border-color: rgba(225, 225, 225, 1);
}
.dashboard .dashboard-title {
  font-weight: 300;
  left: 90px;
}
.dashboard .dashboard-title .__logo,
.dashboard .dashboard-title h1 {
  display: inline;
}
.dashboard .dashboard-title .__logo {
  max-height: 53px;
  vertical-align: top;
}
.dashboard .dashboard-account {
  background: #fff;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 10px;
  right: 93px;
  top: 40px;
  z-index: 50;
}
.dashboard .dashboard-account:hover {
  border-color: rgba(0, 0, 0, 1);
}
.dashboard .dashboard-account .user-name {
  font-size: 18px;
  height: 40px;
  line-height: 1.2;
  padding: 0 0 0 50px;
}
.dashboard .dashboard-account .last-name {
  font-size: 14px;
}
.dashboard .dashboard-account .sign-out {
  color: rgba(0, 0, 0, 0.7);
  display: none;
  font-size: 14px;
  margin: 10px -10px -10px;
  padding: 5px 10px;
}
.dashboard .dashboard-account .sign-out:hover {
  background: #ececec;
}
.dashboard .dashboard-account .sign-ico {
  font-size: 18px;
  margin: 0 5px 0 0;
  vertical-align: -1px;
}
.dashboard .dashboard-account.__opened .sign-out {
  display: block;
}
.log {
  border: 1px solid #dee9f0;
  font-size: 12px;
  height: 300px;
  line-height: 1.4;
  margin: 15px 0;
  overflow: auto;
  padding: 20px;
  white-space: nowrap;
}
.log .__info {
  color: #43b0e6;
}
.log .__warning {
  color: #f0a30a;
}
.log .__debug {
  color: #a6a6a6;
}
.log .__error {
  color: #e51400;
}
.loader {
  display: block;
  height: 75px;
  margin: 0 auto;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  width: 375px;
}
.loaderhide div {
  display: none;
}
.loader div {
  display: flex;
  justify-content: center;
  margin-top: -80px;
  opacity: 0.5;
}
.loader span {
  background: #dee9f0;
  height: 20px;
  position: absolute;
  top: 25px;
  width: 20px;
  -webkit-animation: loader 2.5s infinite ease;
  animation: loader 2.5s infinite ease;
}
.loader span:nth-child(1) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.loader span:nth-child(2) {
  left: 30px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.loader span:nth-child(3) {
  left: 60px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.loader span:nth-child(4) {
  left: 90px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loader span:nth-child(5) {
  left: 120px;
}
.uk-htmleditor-fullscreen {
  left: 235px !important;
}
.nav-bar.__collapsed + .cnt .uk-htmleditor-fullscreen {
  left: 72px !important;
}
.blade-t_ico.__customers,
.list-ico.__customers,
.menu-ico.__customers {
  text-align: center;
}
.blade-t_ico.__customers i,
.list-ico.__customers i,
.menu-ico.__customers i {
  bottom: 0;
  font-style: normal;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.blade-t_ico.__customers i:after,
.blade-t_ico.__customers i:before,
.list-ico.__customers i:after,
.list-ico.__customers i:before,
.menu-ico.__customers i:after,
.menu-ico.__customers i:before {
  content: "";
  font-size: 16px;
  text-shadow: none;
  position: absolute;
  top: 2px;
}
.blade-t_ico.__customers i:before,
.list-ico.__customers i:before,
.menu-ico.__customers i:before {
  left: 0;
}
.blade-t_ico.__customers i:after,
.list-ico.__customers i:after,
.menu-ico.__customers i:after {
  right: 0;
}
.list-ico.__customers,
.menu-ico.__customers {
  position: relative;
}
.ng-cloak,
[ng-cloak],
[ng\:cloak] {
  display: none !important;
}
.__hide,
.ng-hide,
.ng-hide-animate {
  display: none;
}
.__gray,
.no-data {
  color: #999;
}
.block {
  margin: 0 0 20px;
}
.alt-description {
  color: #43b0e6 !important;
}
.blade-content .CodeMirror {
  border: 1px solid #ddd;
  direction: ltr;
  font-size: 13px;
}
::-webkit-scrollbar {
  background: 0 0;
}
::-webkit-scrollbar-track-piece {
  background: #c9d6df;
}
::-webkit-scrollbar-thumb {
  background: #b4c0c8;
}
* {
  scrollbar-arrow-color: #c9d6df;
  -ms-scrollbar-arrow-color: #c9d6df;
  scrollbar-face-color: #b4c0c8;
  -ms-scrollbar-face-color: #b4c0c8;
  scrollbar-shadow-color: #b4c0c8;
  -ms-scrollbar-shadow-color: #b4c0c8;
  scrollbar-track-color: #c9d6df;
  -ms-scrollbar-track-color: #c9d6df;
}
.platform-error-bar {
  background: #e51400;
  color: #fff;
  font-size: 13px;
  left: 0;
  padding: 5px 0;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 9000;
}
.platform-error-bar a {
  color: #fff;
}
.platform-error-bar .close {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 5;
}
.platform-error-bar .error-title {
  height: 30px;
  position: relative;
}
.platform-error-bar .more {
  color: #fff;
  font-size: 12px;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.platform-error-bar .error-detail {
  margin: 5px 0;
  max-height: 250px;
  padding: 10px 20px;
  text-align: left;
  overflow: auto;
}
.error {
  color: #e51400;
  display: block;
  font-size: 12px;
  line-height: 1.4;
  padding: 5px 0;
}
.__disabled,
.__disabled > * {
  opacity: 0.6;
  pointer-events: none;
}
:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.nav-bar .bar .__items .list-item:hover {
  background: #383838;
}
.nav-bar .bar .__items .list-item.__animated {
  background: #43b0e6;
}
.__files .list-item.__selected > .list-block,
.__items .list-item.__selected,
.__items .list-item:focus,
.table .table-item.__selected {
  background: #d9effa;
  color: #000;
}
.__items label.list-item.__selected {
  background: #fff;
}
.__files .list-ico,
.__items .list-ico,
.__items .menu-ico,
.tile-ico {
  color: #a6a6a6;
}
.__files .list-block:hover .list-ico,
.__files .list-item.__selected > .list-block .list-ico,
.__items .list-item.__selected .list-ico,
.__items .list-item:hover .list-ico,
.tile:hover .tile-ico {
  color: #a1c0d4;
}
.nav-bar .bar .__items .list-item:hover .list-ico {
  color: #a6a6a6;
}
.nav-bar .bar .__items .list-item.__animated .list-ico,
.nav-bar .bar .__items .list-item.__animated:hover .list-ico {
  color: #fff;
  text-shadow: none;
}
.dropdown.__more .dropdown-content .list-item .list-fav:hover .list-ico {
  color: #52a2ca;
}
a {
  color: #43b0e6;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
}
.link {
  text-decoration: underline;
}
.link:hover,
a:hover {
  color: #52a2ca;
}
.__link {
  cursor: pointer;
}
.__blue {
  color: #43b0e6;
}
.__items .list-ico,
.__items .menu-ico,
.blade-toolbar .menu-btn,
.breadcrumbs .menu-link,
.dropdown .dropdown-close,
.dropdown-content .list-link,
.menu.__context .menu-item,
.nav-bar .menu-ico,
.nav-bar .menu-link,
.pagination .menu-link,
.text-mini.__link {
  -webkit-transition: color 0.25s ease-in-out 0s;
  transition: color 0.25s ease-in-out 0s;
}
.nav-bar {
  -webkit-transition: width 0.25s ease-in-out 0s;
  transition: width 0.25s ease-in-out 0s;
}
.nav-bar .dropdown.__opened {
  -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s,
    -webkit-transform 0.25s ease-in-out 0s;
}
.cnt,
.uk-htmleditor-fullscreen {
  -webkit-transition: left 0.25s ease-in-out 0s, right 0.25s ease-in-out 0s;
  transition: left 0.25s ease-in-out 0s, right 0.25s ease-in-out 0s;
}
.__items .list-item,
.blade-nav .menu-item,
.btn,
.dropdown-content .list-link,
.form-control.__switch .switch,
.list.__notice .list-link,
.nav-bar .bar .__items .list-item .__animated,
.pagination .menu-link,
.product-img .image,
.table .table-item,
.tree-block {
  -webkit-transition: background-color 0.35s ease-in-out 0s;
  transition: background-color 0.35s ease-in-out 0s;
}
.__items .list-item {
  -webkit-transition: background-color 0.35s ease-in-out 0s,
    width 0.25s ease-in-out 0s;
  transition: background-color 0.35s ease-in-out 0s, width 0.25s ease-in-out 0s;
}
.dashboard .dashboard-account,
.form-input input,
.form-input textarea,
.tile-gallery .gallery-img {
  -webkit-transition: border-color 0.2s ease-in-out 0s;
  transition: border-color 0.2s ease-in-out 0s;
}
.gridster .list-item,
.tile {
  -webkit-transition: box-shadow 0.25s ease-in-out 0s,
    height 0.25s ease-in-out 0s, width 0.25s ease-in-out 0s;
  transition: box-shadow 0.25s ease-in-out 0s, height 0.25s ease-in-out 0s,
    width 0.25s ease-in-out 0s;
}
.tile-ico {
  -webkit-transition: color 0.25s ease-in-out 0s, margin 0.25s ease-in-out 0s;
  transition: color 0.25s ease-in-out 0s, margin 0.25s ease-in-out 0s;
}
.btn,
.tile.__selected:after,
.tile.__selected:before {
  -webkit-transition: opacity 0.25s ease-in-out 0s;
  transition: opacity 0.25s ease-in-out 0s;
}
.progress-bar {
  background-color: #444;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 30px 30px;
  height: 20px;
  margin: 10px 0;
  -webkit-animation: progress 5s infinite linear;
  animation: progress 5s infinite linear;
}
.progress-bar.__auqa {
  background-color: #43b0e6;
}
.progress-bar.__mini {
  background-size: 15px 15px;
  height: 8px;
  -webkit-animation: 2s linear 0s infinite running progress-mini;
  animation: 2s linear 0s infinite running progress-mini;
}
@-webkit-keyframes progress {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -60px 0;
  }
}
@keyframes progress {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -60px 0;
  }
}
@-webkit-keyframes progress-mini {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -30px 0;
  }
}
@keyframes progress-mini {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -30px 0;
  }
}
@-webkit-keyframes loader {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateX(120px);
    transform: translateX(120px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(120px);
    transform: translateX(120px);
  }
  90% {
    opacity: 0;
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }
}
@keyframes loader {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateX(120px);
    transform: translateX(120px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(120px);
    transform: translateX(120px);
  }
  90% {
    opacity: 0;
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }
}
@-webkit-keyframes notify {
  0% {
    background-color: transparent;
  }
  30% {
    background-color: #43b0e6;
  }
  90% {
    background-color: #43b0e6;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes notify {
  0% {
    background-color: transparent;
  }
  30% {
    background-color: #43b0e6;
  }
  90% {
    background-color: #43b0e6;
  }
  100% {
    background-color: transparent;
  }
}
@-webkit-keyframes bell {
  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
}
@keyframes bell {
  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
}
@-webkit-keyframes blade {
  0% {
    -webkit-transform: translateX(-420px);
    transform: translateX(-420px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes blade {
  0% {
    -webkit-transform: translateX(-420px);
    transform: translateX(-420px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.pageWrapper .cnt,
.pageWrapper .nav-bar,
.pageWrapper .nav-bar .dropdown {
  top: 40px;
}
.pageWrapper .cnt {
  top: 60px;
}
.__collapsed .bar .list-link {
  height: 32px;
  width: 32px;
}
a:hover {
  text-decoration: none;
}
.topbar {
  background-color: #252525;
  color: #fff;
  height: 40px;
  position: relative;
  flex: 0 0 auto;
  font-size: 13px;
}
.topbar-btn {
  right: 5px;
  position: absolute;
}
.topbar-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-content: center;
  align-items: center;
}
.topbar-hover:hover {
  background-color: #3d3d3d;
}
.topbar-home {
  flex: 0 0 auto;
  font-size: 20px;
  padding: 0 7px;
  margin-left: 13px;
  height: 100%;
  line-height: 40px;
}
.topbar .topbar-notifications {
  opacity: 1;
  animation-name: notification;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  top: 45px;
  right: 5px;
  background-color: #333;
  width: 300px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  position: fixed;
}
@keyframes notification {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.topbar-notifications .notification-title {
  font-size: 12px;
  height: 20px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
}
.topbar-notifications .notification-message {
  font-size: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
